
let history
export const registerNav = (ref) => {
  history = ref.history
}

const jumpTo = (uri) => {
  history.push(uri);
  // if(!uri.startsWith("/search"))
  //   window.location.reload(true);
}
export const go=(uri)=>{
  history.go(uri)
}

export default jumpTo
