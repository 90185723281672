import Auth from '../Auth'
import jumpTo from '../Navigation'
import axios from 'axios'
import qs from 'qs'
import getClientIP from '../ipAddress'

const URL = 'https://5jhnpguqyk.execute-api.us-east-2.amazonaws.com/default'  // lambda
//const URL = 'http://localhost:8080/api/aggregator'
//const URL = 'https://zack-ecommerce-nodejs.herokuapp.com'
// const URL = 'http://localhost:4000'

const serverCall = (config) => {
  //header authorization
  if (Auth.user_token) {
    //const token = Auth.getToken()
    // config.headers = {
    //   "authorization": token
    // }
  }
  //interceptors handle network error
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (!error.response) {
        error.response = {
          data: 'net work error',
          status: 500
        }
      }
      if(error.response.status===401){
        Auth.logout()
        jumpTo('/login')
        throw error
      }
      return Promise.reject(error);
    });
  config.baseURL = URL
  return axios(config)
}
export default serverCall

export const login = (email, password) => {
  const body =
  {
    "credential": {
      "email": email,
      "password": password
    }
  }
  return serverCall({
    method: 'POST',
    url: '/users/login',
    data: body
  })
    .then(res => {
      Auth.setUserToken(res.data.user_token)
      return res
    })
}

export const storeSearchRequest = (keywords) => {
  let clientIP = ''; 
  getClientIP()
  .then(ip => {clientIP = ip})
  .then(()=>{
    const body =
    {
      "phrase": keywords,
        "date": Date.now(),
        "ip": clientIP
      }
    return axios({
      method: 'POST',
      url: 'http://localhost:8090/searchrequests',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(body)
    })
      .then(res => {
        console.log(res);
        return res;
      })
      .catch(console.log)
  })
  
}