import React, { Component } from 'react'
//import go from '../../modules/Navigation'
import HeaderContainer from '../../components/header/headerContainer'
import Product from './components/Product'
import LoadingAnimation from '../../components/loadingAnimation'
import Filter from './components/Filter'
import styles from './stylesheets/dashboard.module.sass'
import device from '../../modules/mediaQuery'
import MediaQuery from 'react-responsive'
//import { getAllProducts } from '../../redux/action/productAction'


export default class Dashboard extends Component {
  // constructor(props) {
  //   super(props);
    
  // }
    
  redirect=(url) => {
    var CryptoJS = require("crypto-js");
    var bytes = CryptoJS.AES.decrypt(url, 'my-secret-key@123');
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }

  executeSearchIfNeeded() {
    const { search, searchText } = this.props
    const search_string = window.location.pathname.substr(1).replace(/%20/g, ' ')
    if (!this.props.loading && (!searchText || search_string !== searchText)) {
      search(search_string)
    }
  }

  componentDidMount() {
    console.log("Dashboard componentDidMount()");
    this.executeSearchIfNeeded()
  }

  componentDidUpdate(newProps) {
    console.log("Dashboard componentDidUpdate() " + this.props.searchText);
    this.executeSearchIfNeeded()
  }

  componentWillUnmount() {
    console.log("Dashboard componentWillUnmount()");
  }

  render() {
    const {products, applyFilters, applySorting} = this.props;
    return (
      <div className={styles.outbox}>
        {/* Header */}
        <HeaderContainer />
        <div className={styles.box}>
          {/* loading animation */}
          {this.props.loading &&
            <LoadingAnimation />
          }
          {/* filter */}
          <MediaQuery query={device.min.tablet}>
          <div className={styles.filter}>
            <Filter
              applyFilters={applyFilters}
              applySorting={applySorting}
              products={products}
            />
          </div>
          </MediaQuery>
          {/* products */}
          <div className={`row no-gutters ${styles.products}`}>
            {products && products.products.map(p => 
              p.visible?
                <div 
                key={p.viewItemURL}
                className={`col col-md-3 my-1 mx-1 ${styles.product}`}
                //onClick={() => this.props.history.push(`/product-overview/${p._id}`)}
                >
                  <Product
                    title={p.title}
                    price={p.convertedCurrentPrice}
                    category={p.primaryCategory}
                    image={p.galleryURL}
                    shippingType={p.shippingType}
                    redirect={this.redirect}
                    source = {p.source}
                    affiliateURL = {p.promotion_link}
                    discount = {p.discount}
                    fullprice = {p.originalPrice}
                    sortOrder = {p.sortOrder}
                  />
                </div>
                : '')}
          </div>
        </div>
      </div>
    )
  }
}





