import { getAllProducts, applyFilters, applySorting, search } from '../../redux/action/productAction'
import { connect } from 'react-redux'
import Dashboard from './Dashboard'
const mapStoreToProps = state => ({
  products: state.product.products,
  searchText: state.product.searchText,
  loading:state.product.loading
})
const mapDispatchToProps = dispatch => ({
  getAllProducts: ()=>dispatch(getAllProducts()),
  applyFilters:(filter_state, products)=>dispatch(applyFilters(filter_state, products)),
  applySorting:(filter_state, products)=>dispatch(applySorting(filter_state, products)),
  search:(text)=>dispatch(search(text))
})

export default connect(mapStoreToProps, mapDispatchToProps)(Dashboard)