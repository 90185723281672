import {CONFIG}  from './locale'


function GetLocalizedString(id) {
    return LOCALIZATION.find(l=>l.id === id)[CONFIG.REACT_APP_LOCALE];
}

const LOCALIZATION = [
    {"id":"AppLogo","US":"Weekly Deals","CA":"Weekly Deals","AU":"Weekly Deals","UK":"Weekly Deals","DE":"Beste Angebote","FR":"Meilleures affaires","ES":"Tratos de hoy","RU":"Weekly Deals","NL":"Weekly Deals","IL":"דילים היום"},
    {"id":"AppShortName","US":"Best Deals","CA":"Best Deals","AU":"Best Deals","UK":"Best Deals","DE":"Beste Angebote","FR":"Meilleures affaires","ES":"Tratos de hoy","RU":"Best Deals","NL":"Best Deals","IL":"Best Deals"},
    {"id":"AppName","US":"Best Deals","CA":"Best Deals","AU":"Best Deals","UK":"Best Deals","DE":"Beste Angebote","FR":"Meilleures affaires","ES":"Tratos de hoy","RU":"Best Deals","NL":"Best Deals","IL":"Best Deals"},
    {"id":"Deal","US":"Deal","CA":"Deal","AU":"Deal","UK":"Deal","DE":"Deal","FR":"offre","ES":"oferta","RU":"Deal","NL":"Deal","IL":"דיל"},
    {"id":"DealDetails","US":"Deal Details","CA":"Deal Details","AU":"Deal Details","UK":"Deal Details","DE":"Deal Details","FR":"Détails de l'offre","ES":"Detalles de la oferta","RU":"Deal Details","NL":"Deal Details","IL":"פרטי עסקה"},
    {"id":"SearchTheBest","US":"Search The Best","CA":"Search The Best","AU":"Search The Best","UK":"Search The Best","DE":"Suchen Sie nach den besten","FR":"Rechercher les meilleures","ES":"Busque las mejores","RU":"Поиск лучших","NL":"Zoek de beste","IL":"חפש את הדילים"},
    {"id":"OnlineDeals","US":"Online Deals","CA":"Online Deals","AU":"Online Deals","UK":"Online Deals","DE":"Online-Angeboten","FR":"offres en ligne","ES":"ofertas en línea","RU":"онлайн-предложений","NL":"online deals","IL":"הכי טובים באינטרנט"},
    {"id":"SearchDeals","US":"Search Deals...","CA":"Search Deals...","AU":"Search Deals...","UK":"Search Deals...","DE":"Angebote suchen ...","FR":"Rechercher des offres ...","ES":"Buscar ofertas ...","RU":"Искать предложения ...","NL":"Deals zoeken ...","IL":"חפש דילים..."},
    {"id":"FreeShipping","US":" Free Shipping","CA":" Free Shipping","AU":" Free Shipping","UK":" Free Shipping","DE":" Kostenloser Versand","FR":" Livraison gratuite","ES":" Envío gratis","RU":" Free Shipping","NL":" Free Shipping","IL":" משלוח חינם"},
    {"id":"FILTERS","US":"FILTERS","CA":"FILTERS","AU":"FILTERS","UK":"FILTERS","DE":"FILTER","FR":"FILTRES","ES":"FILTROS","RU":"ФИЛЬТРЫ","NL":"FILTERS","IL":"מסננים"},
    {"id":"PRICERANGE","US":"PRICE RANGE","CA":"PRICE RANGE","AU":"PRICE RANGE","UK":"PRICE RANGE","DE":"PREISKLASSE","FR":"ÉCHELLE DES PRIX","ES":"RANGO DE PRECIOS","RU":"ЦЕНОВОЙ ДИАПАЗОН","NL":"PRIJSBEREIK","IL":"טווח מחירים"},
    {"id":"COMMON","US":"COMMON","CA":"COMMON","AU":"COMMON","UK":"COMMON","DE":"VERBREITET","FR":"COMMUN","ES":"COMÚN","RU":"ОБЩИЙ","NL":"GEMEENSCHAPPELIJK","IL":"נפוצים"},
    {"id":"STORE","US":"STORE","CA":"STORE","AU":"STORE","UK":"STORE","DE":"GESCHÄFT","FR":"BOUTIQUE","ES":"TIENDA","RU":"МАГАЗИН","NL":"OP TE SLAAN","IL":"חנות"},
    {"id":"ClearAll","US":"Clear All","CA":"Clear All","AU":"Clear All","UK":"Clear All","DE":"Alles löschen","FR":"Tout effacer","ES":"Limpiar todo","RU":"Очистить все","NL":"Wis alles","IL":"נקה הכל"},
    {"id":"OrderBy","US":"Order by","CA":"Order by","AU":"Order by","UK":"Order by","DE":"Sortieren nach","FR":"Commandé par","ES":"Ordenar por","RU":"Сортировать по","NL":"Bestel per","IL":"מייו לפי"},
]

export default GetLocalizedString
