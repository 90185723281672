import {connect} from 'react-redux'
import Header from './Header'
import {getDepartments} from '../../redux/action/departmentAction'
import {applyFilters, applySorting,search} from '../../redux/action/productAction'


const mapStoreToProps=state=>({
  departments:state.department.departments,
  products: state.product.products,
  searchText: state.product.searchText,
})

const mapDispatchToProps=dispatch=>({
  getDepartments:dispatch(getDepartments()),
  search:(t)=>dispatch(search(t)),
  applyFilters:(filter_state, products)=>dispatch(applyFilters(filter_state, products)),
  applySorting:(filter_state, products)=>dispatch(applySorting(filter_state, products))
})

export default connect(mapStoreToProps,mapDispatchToProps)(Header)