function getClientIP() {
    const promise = new Promise((resolve, reject) => {
        let clientIP = localStorage.getItem("clientIP");
        if(clientIP && clientIP!=='null') {
            resolve(clientIP);
            } else {
            fetch('https://api.ipify.org/?format=json')
            .then(res => res.json())
            .then(data => {
                clientIP=data.ip;
                localStorage.setItem("clientIP", clientIP);
                resolve(clientIP);
            })
            .catch(err => {
                clientIP = '0.0.0.0';
                console.log(err);
                resolve(clientIP);
            });
            }
    });
    return promise;
}

export default getClientIP;