import React, { Component } from 'react'
import { Card } from 'react-bootstrap'
import HeaderContainer from '../../components/header/headerContainer'
//import Product from '../dashboard/components/Product'
import LoadingAnimation from '../../components/loadingAnimation'
import styles from './stylesheets/home.module.sass'
//import category_list from '../../configs/categories'
import Search from '../../components/header/components/Search'
import DEPARTMENTS from '../../configs/categories'
import GetLocalizedString from '../../configs/localization'

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      input: '',
      isToggle: false
    }
  }

handleChange = (v) => {
  this.setState({
    input: v
  })
}
handleSuggest = (v) => {
  this.setState({
    input: v
  })
}
  render() {
    const {
      getAllProducts,
      applyFilters,
      search } = this.props
      let categories = DEPARTMENTS;
      let SearchTheBestStr = GetLocalizedString("SearchTheBest");
      let OnlineDealsStr = GetLocalizedString("OnlineDeals");
    return (
      <div className={styles.outbox}>
        {/* Header */}
        <HeaderContainer />
        <div className={styles.topBackground}>asdas </div>
          <div className={styles.search}>
            <div className={styles.header1}>
              {SearchTheBestStr}
            </div>
            <div className={styles.header1}>
              {OnlineDealsStr}
            </div>
            <Search
                  search={search}
                  onChange={this.handleChange}
                  input_value={this.state.input}
                  handleSuggest={this.handleSuggest}
                />
          </div>
          <div className={styles.categories}>
            <div className={styles.box}>
              <div className={`row no-gutters ${styles.products}`}>
              {categories && categories.DEPARTMENTS.map(c =>
                  <div 
                  key={c}
                  className={`col-8 col-sm-8 col-md-8 col-lg-6 my-1 mx-1 ${styles.department}`}
                  //onClick={() => this.props.history.push(`/product-overview/${p._id}`)}
                  >
                    <a href={`${"/"+ c}`}>
                    <Card
                      border="border-light"
                      className={`${styles.card} p-0`}
                      style={{ height: '100%' }}
                      data-toggle="tooltip" title={c}
                    >
                      {/* {c.name} */}
                      {/* <Card.Body> */}
                      <div className={`${styles.subtitle} p-2`}>{c}</div>
                      {/* <Button onClick={() => window.open(c.name,"_blank", 'noopener,noreferrer')} className="btn btn-primary stretched-link"></Button> */}
                    {/* </Card.Body> */}
                    </Card>
                    </a>
                  </div>
              )}  
              </div>
            </div>
          </div>
        </div>
      
    )
  }
}