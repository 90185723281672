import React from 'react'
import { NavDropdown , Nav} from 'react-bootstrap'
import jumpTo from '../../../modules/Navigation'
import '../stylesheets/dropList.css'

export default function DropList({ department, categories,clickCategory }) {
  if (!categories)
    return (<Nav.Link href={`/${department.replace(/&/g,'')}`}>{department}</Nav.Link>);

  //let firstCategory=categories[0];
  return (
    <NavDropdown title={department}  >
      {categories && categories.map(c =>
        <NavDropdown.Item id='nav-dropdown-item'
        onClick={()=>{
          jumpTo(`/${c.replace(/&/g,'')}`);
          //clickCategory(c)
          //jumpTo('/')
        }}  
        key={c}>{c}
        </NavDropdown.Item>
      )}
    </NavDropdown>
  )
}


