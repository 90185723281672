import { getAllProducts, applyFilters, search } from '../../redux/action/productAction'
import { connect } from 'react-redux'
import Home from './Home'
const mapStoreToProps = state => ({
  products: state.product.products,
  loading:state.product.loading
})
const mapDispatchToProps = dispatch => ({
  getAllProducts: ()=>dispatch(getAllProducts()),
  applyFilters:(filter_state, products)=>dispatch(applyFilters(filter_state, products)),
  search:(t)=>dispatch(search(t))
})

export default connect(mapStoreToProps, mapDispatchToProps)(Home)