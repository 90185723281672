import React from 'react'
import { Card, Button } from 'react-bootstrap'
import styles from '../stylesheets/product.module.sass'
import ebayIcon from '../../../assets/images/ebay.svg'
import aliIcon from '../../../assets/images/aliexpress.svg'
import placeholder from '../../../assets/images/placeholder.svg'
import GetLocalizedString from '../../../configs/localization'

export default function Product({ title, category, price, image, shippingType, redirect, source ,affiliateURL, discount, fullprice, sortOrder}) {
  //var affiliateURL = "https://rover.ebay.com/rover/1/711-53200-19255-0/1?mpre="+
  //  viewItemURL+"&campid=5338721973&toolid=10001&customid=000";

  var CryptoJS = require("crypto-js");
  var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(affiliateURL), 'my-secret-key@123').toString();

  let FreeShippingStr = GetLocalizedString("FreeShipping");
  let DealDetailsStr =  GetLocalizedString("DealDetails");

  return (
    <Card
      border="border-light"
      className={`${styles.card} p-1 shadow`}
      style={{ height: '100%' }}
      data-toggle="tooltip" title={title}
    >
      <Card.Img className={`${styles.sourceimage} float-right`} variant="top" src={source==="eBay"?ebayIcon : aliIcon} />
      <div src={placeholder} style={{ height: '100%' }}>
        <img loading="lazy" className={`${styles.image}`} src={image}  alt=""/>
        {/* <img loading="lazy" className={`${styles.image} p-1`} src={image} alt=""/> */}
      </div>
      <div className={`${styles.subtitle} p-2`}>{title}</div>
  <div className={styles.category}>{category}</div>
      <Card.Body>
        {getRibbon()}
        <div className={styles.category}>{shippingType==="Free"? FreeShippingStr : ""}</div>
        <Card.Subtitle className="my-1 text-muted">
          <div className={styles.title}>
            {price}&nbsp;<span className={styles.strikethrough}>{discount?fullprice:""}</span>
          </div>
        </Card.Subtitle>
        <Button onClick={() => window.open(redirect(ciphertext),"_blank", 'noopener,noreferrer')} className="btn btn-primary stretched-link">{DealDetailsStr}</Button>
      </Card.Body>
    </Card>
  )

  function getRibbon() {
    let text="POPULAR";
    if (sortOrder<=3)
      text="HOT"
    if (!discount && shippingType!=="Free")
      return "";
    else
      return <div className={styles.ribbonwrapper}>
      <div className={`${styles.ribbon} ${discount ? styles.red : text==="HOT" ? styles.green : styles.blue}`}>{discount ? discount + " OFF" : text}</div>
    </div>
  }
}
