import React, { Component } from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import Checkbox from './Checkbox'
import styles from '../stylesheets/filter.module.sass'
import device from '../../../modules/mediaQuery'
import MediaQuery from 'react-responsive'
//import PriceFilter from './PriceFilter';
import Histoslider from '../../../components/histoSlider'
import {formatCurrency} from '../../../configs/locale'
import Filter_md from './Filter_md'
import GetLocalizedString from '../../../configs/localization'

export default class Filter extends Component {
  constructor(props) {
    super(props)
    this.FILTER_CONFIG = {
      order: ['Best match', 'Discount', 'Ascending', 'Descending'],
      store: ['eBay', 'AliExpress'],
      common: ['Free Shipping', 'Discounted'],
      price: ['Less Than $29', '$29 - $39', '$39 - $49', '$49 - $89', 'Greater Than $89']
    }

//    this.currency="$";
    this.initialState = {
      order: 'Best match'
    };
    this.state = this.initialState
  }
  // static getDerivedStateFromProps(props, state) {
  //   if(props.products && props.products.minmax  && !state.minmax) {
  //     return { price: props.products.minmax };
  //   }
  //   if (props.products && props.products.minmax  && (props.products.minmax[0] !== state.minmax[0] || props.products.minmax[1] !== state.products.minmax[1])) {
  //     return { price: props.products.minmax };
  //   }
  //   return null;
  // }

//  componentWillReceiveProps(nextProps) {
  // componentDidUpdate(nextProps) {
  //   // You don't have to do this check first, but it cancd  help prevent an unneeded render
  //   if (nextProps.products && this.props.products && (nextProps.products.minmax[0] !== this.props.products.minmax[0] || nextProps.products.minmax[1] !== this.props.products.minmax[1])) {
  //     this.setState({ price: [this.props.products.minmax[0],this.props.products.minmax[1]] });
  //   }
  // }
  
  handleSort = (selection, event) => {
    //console.log(selection);
    this.setState(prevState => {
      return {
        order: selection
      }
    }, () => {
      this.props.applySorting(this.state, this.props.products)
    })

  }

  handleSliderChange = (e) => {
    const selectionSorted = Array.from(e).sort((a, b) => +a - +b);
    this.setState(prevState => {
      return {
        price: selectionSorted
      }
    }, () => {
      this.props.applyFilters(this.state, this.props.products)
    })
  }

  handleChange = (e, category, name) => {
    let tagName = ''
    let isChecked = false
    //handle div click
    if (name) {
      tagName = name.toUpperCase()
      isChecked = !!!(this.state[category] && this.state[category].includes(name))
    } else {
      // handle input checkbox
      tagName = e.target.name.toUpperCase()
      isChecked = e.target.checked
    }
    this.setState(prevState => {
      //add category value to array
      if (isChecked) {
        //user can only select one order
        if (category === 'order') {
          return {
            [category]: [tagName]
          }
        }
        return {
          [category]: [...prevState[category] || [], tagName]
        }
      } else {
        //remove category value from array
        const new_prop_array = prevState[category].filter(n => n !== tagName)
        return {
          [category]: new_prop_array
        }
      }
    }, () => {
      this.props.applyFilters(this.state, this.props.products)
    })
  }
  handleCloseTag = (category, name) => {
    this.setState(prevState => {
      const new_prop_array = prevState[category].filter(n => n !== name)
      return {
        [category]: new_prop_array
      }
    }, () => this.props.applyFilters(this.state, this.props.products))
  };

  clearAllFilter = () => {
    //alert([this.props.products);
    let minmax = this.props.products ? this.props.products.minmax.slice(0,2) : [];
    let orderState = this.state['order'];
    this.setState({ order: orderState, store: [], common: [], price: minmax }, () => this.props.applyFilters(this.state, this.props.products))
  }

  createHistogramData() {
    if (!this.props.products)
      return [{x0:0,x1:1,y:0}];
    const min = this.props.products.minmax[0];
    const max = this.props.products.minmax[1];
    const n = this.props.products.priceHistogram.length
    const offset = min;
    const range = max-min;
    const step = range/n;

    return Array.from(Array(n)).map((d, i) => ({
      x0: offset + i * step,
      x: offset + (i + 1 ) * step,
      y: this.props.products.priceHistogram[i]
    }));
  }
  
  render() {
    let minmax = [0,100,'$'];
    if (this.props && this.props.products) {
      minmax=this.props.products.minmax;
//      this.currency = minmax[2];
      if(minmax[0]===minmax[1]) minmax[1]++;
    }

    let FiltersStr = GetLocalizedString("FILTERS");
    let PriceRangeStr = GetLocalizedString("PRICERANGE");
    let CommonStr = GetLocalizedString("COMMON");
    let StoreStr = GetLocalizedString("STORE");
    let ClearAllStr = GetLocalizedString("ClearAll");
    let OrderByStr = GetLocalizedString("OrderBy");

    return (
      <div>
        {/* <MediaQuery query={device.min.tablet}> */}
          <div className={styles.outbox}>
            <div className={styles.box}>
              {/* order */}
              <div className={styles.sorting}>
                <DropdownButton className={styles.sortingDropdown} variant='secondary' style={{}} onSelect={this.handleSort} drop='right' 
                  title={`${OrderByStr}: ${this.state['order']}`}>                   
                    {this.FILTER_CONFIG['order'].map(n =>
                      <Dropdown.Item className={styles.sortingDropdownItem}
                        name={n}
                        key={n}
                        eventKey={n}
                        active={this.state['order'].toUpperCase() === n.toUpperCase()}>
                        {n}
                      </Dropdown.Item>
                    )}
                  
                </DropdownButton>
              </div>
              {/* filter */}
              <div className={styles.title}>
                {FiltersStr}
                {/* <div className={styles.title_border}></div> */}
                <div className={styles.clearLink} onClick={this.clearAllFilter}>
                  <button className={styles.clearLink}>{ClearAllStr}</button>
                </div>
              </div>
              <div className={styles.content}>
                {/* order */}
                {/* <div className={styles.block}>
                  <div className={styles.sub_title}>
                    ORDER
                    
                  </div>
                  {this.FILTER_CONFIG['order'].map(n =>
                    <Checkbox
                      key={n}
                      onChange={this.handleChange}
                      name={n}
                      category='order'
                      isChecked={(this.state['order'] && this.state['order'].includes(n.toUpperCase())) || false}
                    />
                  )}
                </div> */}
                {/* price */}
                <div className={styles.block}>
                  <div className={styles.sub_title}>
                    {PriceRangeStr}
                  </div>
                  <div>
                    <Histoslider
                      //showOnDrag
                      // An array of objects to create the histogram
                      data={this.createHistogramData()}
                      // How much to pad the slider and histogram by, defaults to 20
                      padding={10}
                      // The extent of the selection, this doesn't have to be sorted (and you shouldn't sort it to store it)
                      selection={this.state.price}
                      // A function to handle a change in the selection
                      onChange={this.handleSliderChange} 
                      //
                      formatLabelFunction={(value) => {return formatCurrency(value);}}
                    />
                  </div>
                  {/* <div>
                  <PriceFilter 
                    minPrice={minmax[0]}
                    maxPrice={minmax[1]}
                    currency={minmax[2]}
                    onChange={this.handleSliderChange}
                  />
                  </div> */}
                  <div className="d-flex justify-content-between"><br></br></div>
                    {/* <div>
                      
                      <RangeSlider
                        step={2}
                        onChange={this.handleSliderChange}
                        value={{start:this.state['price'][0], end:this.state['price'][1]}}
                        min={this.props.minmax[0]}
                        max={this.props.minmax[1]}
                        wrapperClassName={styles.slider}
                        highlightedTrackClassName={styles.sliderHighlightedTrack}
                        trackClassName={styles.sliderTrack}
                        handleStyle={styles.handleStyle}
                      />
                    </div> */}
                    {/* <div className="d-flex justify-content-between">
                      <span>{this.state['price'][0]+this.currency}</span>
                      <span>{this.state['price'][1]+this.currency}</span>               
                    </div> */}
                  {/* {this.FILTER_CONFIG['price'].map(n =>
                    <Checkbox
                      key={n}
                      onChange={this.handleChange}
                      name={n}
                      category='price'
                      isChecked={(this.state['price'] && this.state['price'].includes(n.toUpperCase())) || false}
                    />
                  )} */}
                </div>
                {/* Common Filters */}
                <div className={styles.block}>
                  <div className={styles.sub_title}>
                    {CommonStr}
                  </div>
                  {this.FILTER_CONFIG['common'].map(n =>
                    <Checkbox
                      key={n}
                      onChange={this.handleChange}
                      name={n}
                      category='common'
                      isChecked={(this.state['common'] && this.state['common'].includes(n.toUpperCase())) || false}
                    />
                  )}
                </div>
                {/* store */}
                <div className={styles.block}>
                  <div className={styles.sub_title}>
                    {StoreStr}
                  </div>
                  {this.FILTER_CONFIG['store'].map(n =>
                    <Checkbox
                      key={n}
                      onChange={this.handleChange}
                      name={n}
                      category='store'
                      isChecked={(this.state['store'] && this.state['store'].includes(n.toUpperCase())) || false}
                    />
                  )}
                </div>
                
              </div>
              {/* <div className={styles.clear_btn} onClick={this.clearAllFilter}>
                <button>Clear All</button>
              </div> */}
              {/* filter tags */}
              {/* <div className={styles.tags}>
                {
                  Object.keys(this.state).map(c => (
                    c !== 'price' && this.state[c] && this.state[c].map(n => (
                      <div key={n} className={styles.tag}>
                        <div className={styles.tag_content}>
                          {n}
                        </div>
                        <div
                          className={styles.tag_close}
                          onClick={() => this.handleCloseTag(c, n)}
                        >
                          x
                      </div>
                      </div>
                    ))))}
              </div> */}
            </div>
          </div>
        {/* </MediaQuery > */}
        {/* <MediaQuery query={device.max.tablet}>
          <Filter_md
            onChange={this.handleChange}
            clear={this.clearAllFilter}
            configs={this.FILTER_CONFIG}
            selected_name={this.state}
          />
        </MediaQuery> */}
      </div >
    )
  }
}

