//import serverCall from '../../modules/serverCall'
import DEPARTMENTS from '../../configs/categories'

export const getDepartments=()=>dispatch=>{
  dispatch({
    type:GET_DEPARTMENTS_BEGIN,
  })
  let res = {data:{departments:DEPARTMENTS}};
  // let res = {data:{departments: [
  //   {departmentName:'Computers'},
  //   {departmentName:'Electronics'},
  //   {departmentName:'Automotive'},
  //   {departmentName:'Beauty & Health'},
  //   {departmentName:'Furniture'},
  //   {departmentName:'More', categories:'Kitchen;Garden;Appliances;Computer & Office;Office & School Supplies;Lights & Lighting;Arts & Crafts;Mother & Kids;SmartPhones;Security & Protection;Shoes;Jewelry & Accessories;Collectibles & Fine Art;Digital Music;Food;Sports & Entertainment'}
  // ]}};
  dispatch({
    type:GET_DEPARTMENTS_SUCCESS,
    payload:res
  })
  return res;
  // return;
  // return serverCall({
  //   method:'GET',
  //   url:'/departments'
  // })
  // .then(res=>{
  //   dispatch({
  //     type:GET_DEPARTMENTS_SUCCESS,
  //     payload:res
  //   })
  //   return res
  // })
  // .catch(error=>{
  //   dispatch({
  //     type:GET_DEPARTMENTS_FAIL,
  //     payload:{error}
  //   })
  //   return error
  // })
}

export const GET_DEPARTMENTS_BEGIN = 'GET_DEPARTMENTS_BEGIN'
export const GET_DEPARTMENTS_SUCCESS='GET_DEPARTMENTS_SUCCESS'
export const GET_DEPARTMENTS_FAIL='GET_DEPARTMENTS_FAIL'