module.exports.LOCALE = [
    {locale:"US",format:"en-US",currency:"USD",symbol:"$",cacheURL:"https://departments-data.s3.us-east-2.amazonaws.com/cache"},
    {locale:"UK",format:"en-GB",currency:"GBP",symbol:"£",cacheURL:"https://uk-cache.s3.eu-west-2.amazonaws.com/cache"},
    {locale:"DE",format:"de-DE",currency:"EUR",symbol:"€",cacheURL:"https://de-cache.s3.eu-west-2.amazonaws.com/cache"},
    {locale:"FR",format:"fr-FR",currency:"EUR",symbol:"€",cacheURL:"https://il-cache.s3.eu-west-3.amazonaws.com/cache"},
    {locale:"ES",format:"es-ES",currency:"EUR",symbol:"€",cacheURL:"https://il-cache.s3.eu-west-3.amazonaws.com/cache"},
    {locale:"IL",format:"he-IL",currency:"ILS",symbol:"₪",cacheURL:"https://il-cache.s3.eu-west-3.amazonaws.com/cache"}
]

const localizations = [
  {locale:"US", currency:"USD", symbol:"$", format:"en-US", eBay:"EBAY-US"},
  {locale:"GB", currency:"GBP", symbol:"£", format:"en-GB", eBay:"EBAY-GB"},
  {locale:"UK", currency:"GBP", symbol:"£", format:"en-GB", eBay:"EBAY-GB"},
  {locale:"CA", currency:"CAD", symbol:"$", format:"en-CA", eBay:"EBAY-CAD"},
  {locale:"AU", currency:"AUD", symbol:"$", format:"en-AU", eBay:"EBAY-AU"},
  {locale:"DE", currency:"EUR", symbol:"€", format:"de-DE", eBay:"EBAY-DE"},
  {locale:"ES", currency:"EUR", symbol:"€", format:"es-ES", eBay:"EBAY-ES"},
  {locale:"NL", currency:"EUR", symbol:"€", format:"nl-NL", eBay:"EBAY-NL"},
  {locale:"FR", currency:"EUR", symbol:"€", format:"fr-FR", eBay:"EBAY-FR"},
  {locale:"IL", currency:"ILS", symbol:"₪", format:"he-IL", eBay:"EBAY-IL"},
  {locale:"JP", currency:"JPY", symbol:"¥", format:"ja-JP", eBay:"EBAY-JP"},
  {locale:"CN", currency:"CNY", symbol:"¥", format:"zh-CN", eBay:"EBAY-CN"},
  {locale:"HK", currency:"HKD", symbol:"$", format:"hk-HK", eBay:"EBAY-HK"},
  {locale:"RU", currency:"RUB", symbol:"₽", format:"ru-RU", eBay:"EBAY-RU"}
]


require('dotenv').config();

module.exports.CONFIG = {
  NODE_ENV: process.env.NODE_ENV,
  PORT: process.env.PORT || 8090,
  REACT_APP_LOCALE: process.env.REACT_APP_LOCALE
}


module.exports.formatCurrency= (value) => {
  const locale = module.exports.CONFIG.REACT_APP_LOCALE;  
  var loc = module.exports.LOCALE.find(l => l.locale === locale);
 // if (!loc)
 //     loc = localization[0];

  return Intl.NumberFormat(loc.format, { style: 'currency', currency: loc.currency }).format(value);
}