import React, { Component, useContext } from 'react'
import styles from './stylesheets/header.module.sass'
//import UserHeader from './components/UserHeader'
import Menu from './components/Menu'
import Search from './components/Search'
import jumpTo from '../../modules/Navigation'
import device from '../../modules/mediaQuery'
import MediaQuery from 'react-responsive'
import {Accordion, Card} from 'react-bootstrap'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Filter from '../../pages/dashboard/components/Filter'
import GetLocalizedString from '../../configs/localization'


function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <i className={`${styles.arrow} ${isCurrentEventKey?styles.up:styles.down}`}></i>
  );
}

export default class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      input: '',
      isToggle: false
    }
  }
  handleChange = (v) => {
    this.setState({
      input: v
    })
  }
  handleSuggest = (v) => {
    this.setState({
      input: v
    })
  }
  handleToggle = () => {
    this.setState(prevState => {
      return {
        isToggle: !prevState.isToggle
      }
    })
  }
  closeToggle = () => {
    this.setState({
      isToggle: false
    })
  }

  componentDidMount = () => {
    console.log(" Header componentDidMount")
  }

  componentDidUpdate = (newProps) => {
    console.log(" Header componentDidUpdate " + this.state.isToggle)
    //this.closeToggle();
  }

  
  prepareDepartments = (departments, itemsOnScreen, windowWidth) => {
    
    if (!departments)
      return null;  
    let deps = [];
    for(let i=0;i<departments.DEPARTMENTS.length;i++) {
      if (windowWidth<770 || i<itemsOnScreen)
        deps.push({departmentName: departments.DEPARTMENTS[i]});
      else {
        deps.push({departmentName: 'More', categories: departments.DEPARTMENTS.slice(i).join(';')});
        break;
      }
    }
    return deps;
    // res = {data:{departments: [
    //   {departmentName:'Computers'},
    //   {departmentName:'Electronics'},
    //   {departmentName:'Automotive'},
    //   {departmentName:'Beauty & Health'},
    //   {departmentName:'Furniture'},
    //   {departmentName:'More', categories:'Kitchen;Garden;Appliances;Computer & Office;Office & School Supplies;Lights & Lighting;Arts & Crafts;Mother & Kids;SmartPhones;Security & Protection;Shoes;Jewelry & Accessories;Collectibles & Fine Art;Digital Music;Food;Sports & Entertainment'}
    // ]}};
  }

  render() {
    const {
      departments,
      products,
      searchText,
      search,
      applyFilters,
      applySorting } = this.props;
    let visibility = "hide";
    let path = window.location.pathname;
    let isRoot = path === "/";
    let windowWidth = window.innerWidth
          || document.documentElement.clientWidth
          || document.body.clientWidth;
    console.log(new Date(Date.now()).toUTCString() + " path: " + path + " windowWidth: " + windowWidth);

    let itemsOnScreen =  (windowWidth-(isRoot?120:650))/150;
    //  if (isRoot)
    //    itemsOnScreen += 3;
    let deps = this.prepareDepartments(departments, itemsOnScreen, windowWidth);
    
    if (this.state.isToggle) {
      visibility = "show"
    }

    let AppLogoStr = GetLocalizedString("AppLogo");

    return (
      <div className={styles.outbox}>
        {/* larger than 768px */}
        <MediaQuery query={device.min.tablet}>
          {/* top user header */}
          {/* <div className={styles.user_header}>
            <UserHeader
              user_token={user_token}
            />
          </div> */}
          {/* menu header */}
          <div className={styles.content}>
            <div className={styles.left}>
              {/* logo */}
              <div className={styles.logo}
                onClick={() => {
                  //getAllProducts()
                  jumpTo('/')
                }}
              >
                {AppLogoStr}
              </div>
            </div>
            {!isRoot?<div className={styles.mid}>
            <Search
                search={search}
                onChange={this.handleChange}
                input_value={this.state.input}
                handleSuggest={this.handleSuggest}
              />
            </div>:""}
            <div className={styles.right}>
            <Menu
                departments={deps}
                
              />
            </div>
          </div>
        </MediaQuery>
        {/* smaller than 768px */}
        <MediaQuery query={device.max.tablet}>
          <div className={styles.content}>
            <div className={`${styles.toggle_outbox}`}>
              {/* toggle content */}
              <div id="toggle" className={styles[`${visibility}`]}>
                <div className={styles.toggle_content}>
                  {/* <div className={styles.toggle_top}>
                  </div> */}
                  <div className={styles.side_title}>
                    MENU
                    <div
                      className={styles.side_title_close}
                      onClick={this.closeToggle}
                    >
                      x
                    </div>
                  </div>
                  <Search
                    search={search}
                    onChange={this.handleChange}
                    input_value={this.state.input}
                    handleSuggest={this.handleSuggest}
                  />
                  <Accordion defaultActiveKey={isRoot?"0":"1"}>
                    <Card className={styles.accordion_style}>
                      <Accordion.Toggle className={styles.accordion_header_style} as={Card.Header} eventKey="0">
                        CATEGORY &nbsp; <ContextAwareToggle eventKey="0"></ContextAwareToggle>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <div>
                          <Menu
                            departments={deps}
                          />
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card className={styles.accordion_style}>
                      <Accordion.Toggle className={styles.accordion_header_style} as={Card.Header} eventKey="1">
                        FILTERS &nbsp; <ContextAwareToggle eventKey="1"></ContextAwareToggle> 
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                        <Filter
                          applyFilters={applyFilters}
                          applySorting={applySorting}
                          products={products}
                        />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </div>
            </div>
            {/* logo */}
            <div className={styles.logo}
              onClick={() => {
                //getAllProducts()
                jumpTo('/')
              }}
            >
              {AppLogoStr}
            </div>

            {/* toggle icon */}
            {/* <div className={`${styles.toggle_icon} ${styles[`${visibility}`]}`} onClick={this.handleToggle}> */}
            <div className={`${styles.toggle_icon}`} onClick={this.handleToggle}>
              <div className={styles.bar1}></div>
              <div className={styles.bar2}></div>
              <div className={styles.bar3}></div>
            </div>
            
          </div>
        </MediaQuery>
      </div >
    )
  }
}



