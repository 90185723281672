import serverCall  from '../../modules/serverCall'
import jumpTo from '../../modules/Navigation'
import {LOCALE, CONFIG}  from '../../configs/locale'
//import generateFilterString from '../../pages/dashboard/utils/generateFilterString'

export const getAllProducts=()=>dispatch=>{
  dispatch({
    type:GET_ALL_PRODUCTS_BEGIN,
  })
  let res = {data:{products: [], minmax:[0,100,"$"]}};
  let path = window.location.pathname;
  if(path.startsWith('search')) {
    dispatch({
      type: GET_ALL_PRODUCTS_SUCCESS,
      payload: res
    })
    return res;
  }
  return new Promise((resolve,reject)=>{
    if(path.length===1)
      path="";
    let url = `https://departments-data.s3.us-east-2.amazonaws.com/data${path.toLowerCase()}/data.json`;
    fetch(url).then(response=>response.json()).then(prods=>{
    //import(`../../assets/data${path}/index.js`).then(prods=>{.j
      res = {data:{products: prods.products , minmax:[0,100,"$"]}};
      dispatch({
        type: GET_ALL_PRODUCTS_SUCCESS,
        payload: res
      })
      resolve(res);
    }).catch(error=>{
        dispatch({
          type: GET_ALL_PRODUCTS_FAIL,
          payload: {error}
        });
        reject(error);
    });
  });

  // return serverCall({
  //   method:'GET',
  //   url:`/allSearch`
  //   //url:`/products`
  //   //url:`/api/ebay/ebaySearch?searchText=magicyoyo`
  // })
  // .then(res=>{
  //   dispatch({
  //     type: GET_ALL_PRODUCTS_SUCCESS,
  //     payload: res
  //   })
  //   return res
  // })
  // .catch(error=>{
  //   dispatch({
  //     type: GET_ALL_PRODUCTS_FAIL,
  //     payload: {error}
  //   })
  //   return error
  // })
}

export const getProduct=(id)=>dispatch=>{
  dispatch({
    type:GET_PRODUCT_BEGIN,
  })
  return serverCall({
    method:'GET',
    url:`/products/${id}`
  })
  .then(res=>{
    dispatch({
      type: GET_PRODUCT_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: GET_PRODUCT_FAIL,
      payload: {error}
    })
    return error
  })
}

export const getProductsByCategory=(c)=>dispatch=>{
  dispatch({
    type:GET_PRODUCTS_BY_CATEGORY_BEGIN,
  })
  jumpTo(`/${c}`);
  let res;
  let path = window.location.pathname;
  return new Promise((resolve,reject)=>{
    import('../../assets/data' + path).then(prods=>{
      res = {data:{products: prods.PRODUCTS.products , minmax:[0,100,"$"]}};
      dispatch({
        type: GET_PRODUCTS_BY_CATEGORY_SUCCESS,
        payload: res
      })
      resolve(res);
    }).catch(error=>{
      dispatch({
        type: GET_PRODUCTS_BY_CATEGORY_FAIL,
        payload: {error}
      })
      reject(error);
    });
  });

  // return serverCall({
  //   method:'GET',
  //   url:`/products?category=${c}`
  // })
  // .then(res=>{
  //   dispatch({
  //     type: GET_PRODUCTS_BY_CATEGORY_SUCCESS,
  //     payload: res
  //   })
  //   return res
  // })
  // .catch(error=>{
  //   dispatch({
  //     type: GET_PRODUCTS_BY_CATEGORY_FAIL,
  //     payload: {error}
  //   })
  //   return error
  // })
}

export const search=(text)=>dispatch=>{
  dispatch({
    type:SEARCH_BEGIN,
  })
  //storeSearchRequest(text);

  return new Promise((resolve,reject)=>{
    let path = window.location.pathname;
    //let res = {data:{products: [], minmax:[0,100,"$"]}};
    if(path.length===1)
      path="";
    path = path.replace(/\//g,'').replace(/%20/g,'%2520');
    let cacheURL = LOCALE.find(l=>l.locale === CONFIG.REACT_APP_LOCALE).cacheURL;
    let url = `${cacheURL}/${CONFIG.REACT_APP_LOCALE}/${path.toLowerCase()}/data.json`;
    fetch(url).then(response=>{
      //console.log(response);
      if(response.status === 200) {
        return response.json();
      } else {
        return serverCall({
          method:'GET',
          url:`/allSearch?locale=${CONFIG.REACT_APP_LOCALE}&searchText=${text}`
          //url:`/products?searchText=${text}`
        });
      }
      }).then(res=>{
    //import(`../../assets/data${path}/index.js`).then(prods=>{.j
      if(!res.data) {
        //res = JSON.parse(res);
        res = {data: res};
      }
      
      dispatch({
        type: SEARCH_SUCCESS,
        searchText: text,
        payload: res
      })
      resolve(res);
    }).catch(error=>{
        dispatch({
          type: SEARCH_FAIL,
          payload: {error}
        });
        reject(error);
    });
  });

  // return serverCall({
  //   method:'GET',
  //   url:`/allSearch?locale=IL&searchText=${text}`
  //   //url:`/products?searchText=${text}`
  // })
  // .then(res=>{
  //   dispatch({
  //     type: SEARCH_SUCCESS,
  //     searchText: text,
  //     payload: res
  //   })
  //   return res
  // })
  // .catch(error=>{
  //   dispatch({
  //     type: SEARCH_FAIL,
  //     payload: {error}
  //   })
  //   return error
  // })
}
export const applySorting=(filter_state, data)=>dispatch=>{
  dispatch({
    type:APPLY_SORTING_BEGIN,
  })
  try { 
    //let filter_string=generateFilterString(filter_state);
    let {products} = data;
    let order = filter_state['order'];

    let productsSorted = Array.from(products).sort((a, b) => {
      switch(order) {
        case 'Best match':
          return a.sortOrder - b.sortOrder;
        case 'Discount':
          const aVal = a.discount?parseFloat(a.discount):0;
          const bVal = b.discount?parseFloat(b.discount):0;
          return bVal - aVal;
        case 'Ascending':
          return a.convertedPrice - b.convertedPrice;
        case 'Descending':
          return b.convertedPrice - a.convertedPrice;
        default:
          return a.sortOrder - b.sortOrder;
      }
    });

    data.products = productsSorted;

    dispatch({
      type: APPLY_SORTING_SUCCESS,
      payload: data
    });
  }
  catch (error) {
    dispatch({
      type: APPLY_SORTING_FAIL,
      payload: {error}
    });
    return error;
  };
}

export const applyFilters=(filter_state, data)=>dispatch=>{
  dispatch({
    type:APPLY_FILTERS_BEGIN,
  })
  try {
    //let filter_string=generateFilterString(filter_state);
    let {products, minmax} = data;
    if(filter_state.price && filter_state.price.length<2)
      filter_state.price = minmax;

    products.forEach(p=>{
      p.visible=true;
      if(filter_state.price)
        p.visible=p.visible &&(filter_state.price && (p.convertedPrice>=filter_state.price[0] && p.convertedPrice<=filter_state.price[1]));
      if(filter_state.store && filter_state.store.length>0)
        p.visible=p.visible && filter_state.store.includes(p.source.toUpperCase());
      if(filter_state.common && filter_state.common.includes("DISCOUNTED"))
        p.visible=p.visible && p.discount;
      if(filter_state.common && filter_state.common.includes("FREE SHIPPING"))
        p.visible=p.visible && p.shippingType==="Free";
    });
    dispatch({
      type: APPLY_FILTERS_SUCCESS,
      payload: data
    });
  }
  catch (error) {
    dispatch({
      type: APPLY_FILTERS_FAIL,
      payload: {error}
    });
    return error;
  };

  /*
  return serverCall({
    method:'GET',
    url:`/products?${filter_string}`
  })
  .then(res=>{
    dispatch({
      type: APPLY_FILTERS_SUCCESS,
      payload: res
    })
    return res
  })
  .catch(error=>{
    dispatch({
      type: APPLY_FILTERS_FAIL,
      payload: {error}
    })
    return error
  })
  */
}

export const APPLY_FILTERS_BEGIN='APPLY_FILTERS_BEGIN'
export const APPLY_FILTERS_SUCCESS='APPLY_FILTERS_SUCCESS'
export const APPLY_FILTERS_FAIL='APPLY_FILTERS_FAIL'

export const APPLY_SORTING_BEGIN='APPLY_SORTING_BEGIN'
export const APPLY_SORTING_SUCCESS='APPLY_SORTING_SUCCESS'
export const APPLY_SORTING_FAIL='APPLY_SORTING_FAIL'

export const SEARCH_BEGIN='SEARCH_BEGIN'
export const SEARCH_SUCCESS='SEARCH_SUCCESS'
export const SEARCH_FAIL='SEARCH_FAIL'


export const GET_ALL_PRODUCTS_BEGIN='GET_ALL_PRODUCTS_BEGIN'
export const GET_ALL_PRODUCTS_SUCCESS='GET_ALL_PRODUCTS_SUCCESS'
export const GET_ALL_PRODUCTS_FAIL='GET_ALL_PRODUCTS_FAIL'

export const GET_PRODUCT_BEGIN='GET_PRODUCT_BEGIN'
export const GET_PRODUCT_SUCCESS='GET_PRODUCT_SUCCESS'
export const GET_PRODUCT_FAIL='GET_PRODUCT_FAIL'

export const GET_PRODUCTS_BY_CATEGORY_BEGIN='GET_PRODUCTS_BY_CATEGORY_BEGIN'
export const GET_PRODUCTS_BY_CATEGORY_SUCCESS='GET_PRODUCTS_BY_CATEGORY_SUCCESS'
export const GET_PRODUCTS_BY_CATEGORY_FAIL='GET_PRODUCTS_BY_CATEGORY_FAIL'