import React from 'react'
import styles from '../stylesheets/search.module.sass'
import AutoComplete from '../../autoComplete/AutoCompleteContainer'
import jumpTo from '../../../modules/Navigation'
import searchIcon from '../../../assets/images/search.svg';

export default function Search({
  search,
  onChange,
  handleSuggest,
  input_value
}) {
  return (
    <div className={styles.outbox}>
      {/* search input */}
        <div className={styles.auto}>
          <AutoComplete
            searchclick={search}
            onChange={onChange}
            suggest_value={handleSuggest}
          />
        </div>
        <div>
          <button className={styles.btn} onClick={() => {
                // search(input_value).then(res => jumpTo('/search'));
                search(input_value).then(res => jumpTo(`/${input_value.replace(/&/g,'').replace(/ /g,'%20')}`));
              }}>
                <img src={searchIcon} alt="Search" className={`${styles.searchIcon}`} data-toggle="tooltip" title="Search" />
          </button>
        </div>
    </div>
  )
}
