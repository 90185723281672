
module.exports.DEPARTMENTS = [
    'Computers',
    'Electronics',
    'Automotive',
    'Beauty & Health',
    'Furniture',
    'Kitchen',
    'Garden',
    'Appliances',
    'Computer & Office',
    'Office & School Supplies',
    'Lights & Lighting',
    'Arts & Crafts',
    'Mother & Kids',
    'SmartPhones',
    'Security & Protection',
    'Shoes',
    'Jewelry & Accessories',
    'Collectibles & Fine Art',
    'Digital Music',
    'Food',
    'Sports & Entertainment'
];
/*
module.exports.PARENT_CATEGORIES = [
    {"category_id":34,"category_name":"Automobiles, Parts & Accessories"},
    {"category_id":21,"category_name":"Office & School Supplies"},
    {"category_id":200574005,"category_name":"Underwear"},
    {"category_id":3,"category_name":"Apparel & Accessories"},
    {"category_id":66,"category_name":"Beauty & Health"},
    {"category_id":7,"category_name":"Computer & Office"},
    {"category_id":44,"category_name":"Consumer Electronics"},
    {"category_id":502,"category_name":"Electronic Components & Supplies"},
    {"category_id":2,"category_name":"Food"},
    {"category_id":1503,"category_name":"Furniture"},
    {"category_id":200165144,"category_name":"Hair Extensions & Wigs"},
    {"category_id":15,"category_name":"Home & Garden"},
    {"category_id":6,"category_name":"Home Appliances"},
    {"category_id":13,"category_name":"Home Improvement"},
    {"category_id":36,"category_name":"Jewelry & Accessories"},
    {"category_id":39,"category_name":"Lights & Lighting"},
    {"category_id":1524,"category_name":"Luggage & Bags"},
    {"category_id":1501,"category_name":"Mother & Kids"},
    {"category_id":509,"category_name":"Phones & Telecommunications"},
    {"category_id":30,"category_name":"Security & Protection"},
    {"category_id":322,"category_name":"Shoes"},
    {"category_id":200001075,"category_name":"Special Category"},
    {"category_id":18,"category_name":"Sports & Entertainment"},
    {"category_id":1420,"category_name":"Tools"},
    {"category_id":26,"category_name":"Toys & Hobbies"},
    {"category_id":1511,"category_name":"Watches"},
    {"category_id":320,"category_name":"Weddings & Events"},
    {"category_id":200000343,"category_name":"Men's Clothing"},
    {"category_id":200000532,"category_name":"Novelty & Special Use"},
    {"category_id":200000297,"category_name":"Apparel Accessories"},
    {"category_id":200000345,"category_name":"Women's Clothing"},
    {"category_id":201169612,"category_name":"Virtual Products"},
    {"category_id":201355758,"category_name":"Motorcycle Equipments & Parts"}];
module.exports.CATEGORIES = [{"name":"Automotive","ali_name":"Automobiles, Parts & Accessories","ali_id":34},
{"name":"Auto Sale","ali_name":"Auto Sale","ali_id":200004218,"ali_parent_id":34},
{"name":"Auto Replacement Parts","ali_name":"Auto Replacement Parts","ali_id":200000212,"ali_parent_id":34},
{"name":"Car Electronics","ali_name":"Car Electronics","ali_id":200000285,"ali_parent_id":34},
{"name":"Car Lights","ali_name":"Car Lights","ali_id":200002005,"ali_parent_id":34},
{"name":"Car Repair Tool","ali_name":"Car Repair Tool","ali_id":200259142,"ali_parent_id":34},
{"name":"Car Wash and Maintenance","ali_name":"Car Wash & Maintenance","ali_id":200260142,"ali_parent_id":34},
{"name":"Exterior Accessories","ali_name":"Exterior Accessories","ali_id":200003427,"ali_parent_id":34},
{"name":"Interior Accessories","ali_name":"Interior Accessories","ali_id":200003411,"ali_parent_id":34},
{"name":"Motorcycle Accessories and Parts","ali_name":"Motorcycle Accessories & Parts","ali_id":200000242,"ali_parent_id":34},
{"name":"Other Vehicle Parts and Accessories","ali_name":"Other Vehicle Parts & Accessories","ali_id":200004043,"ali_parent_id":34},
{"name":"Car Services","ali_name":"Car Services","ali_id":201268984,"ali_parent_id":34},
{"name":"Office and School Supplies","ali_name":"Office & School Supplies","ali_id":21},
{"name":"Art Supplies","ali_name":"Art Supplies","ali_id":211111,"ali_parent_id":21},
{"name":"Art Tool Kits","ali_name":"Art Tool Kits","ali_id":100003131,"ali_parent_id":21},
{"name":"Educational Equipment and Supplies","ali_name":"Educational Equipment & Supplies","ali_id":100003135,"ali_parent_id":21},
{"name":"Journal, Periodical and Magazines","ali_name":"Journal, Periodical & Magazines","ali_id":2213,"ali_parent_id":21},
{"name":"Maps and Atlases","ali_name":"Maps & Atlases","ali_id":2209,"ali_parent_id":21},
{"name":"Paper and Printable Media","ali_name":"Paper & Printable Media","ali_id":2112,"ali_parent_id":21},
{"name":"Presentation Supplies","ali_name":"Presentation Supplies","ali_id":212002,"ali_parent_id":21},
{"name":"Printing Products","ali_name":"Printing Products","ali_id":200001562,"ali_parent_id":21},
{"name":"School Supplies","ali_name":"School Supplies","ali_id":100003125,"ali_parent_id":21},
{"name":"Tapes, Adhesives and Fasteners","ali_name":"Tapes, Adhesives & Fasteners","ali_id":100003134,"ali_parent_id":21},
{"name":"Books（old）","ali_name":"Books（old）","ali_id":2202,"ali_parent_id":21},
{"name":"Desk Accessories and Organizer","ali_name":"Desk Accessories & Organizer","ali_id":211106,"ali_parent_id":21},
{"name":"Notebooks and Writing Pads","ali_name":"Notebooks & Writing Pads","ali_id":100003155,"ali_parent_id":21},
{"name":"Office Binding Supplies","ali_name":"Office Binding Supplies","ali_id":100003129,"ali_parent_id":21},
{"name":"Pens, Pencils and Writing Supplies","ali_name":"Pens, Pencils & Writing Supplies","ali_id":200001743,"ali_parent_id":21},
{"name":"Stationery Sticker","ali_name":"Stationery Sticker","ali_id":200004276,"ali_parent_id":21},
{"name":"Drafting Supplies","ali_name":"Drafting Supplies","ali_id":2139,"ali_parent_id":21},
{"name":"Accounting Supplies","ali_name":"Accounting Supplies","ali_id":200652001,"ali_parent_id":21},
{"name":"Books","ali_name":"Books","ali_id":201236701,"ali_parent_id":21},
{"name":"Mailing and Shipping","ali_name":"Mailing & Shipping","ali_id":201330702,"ali_parent_id":21},
{"name":"Filing Products","ali_name":"Filing Products","ali_id":201338004,"ali_parent_id":21},
{"name":"Underwear","ali_name":"Underwear","ali_id":200574005},
{"name":"Bikinis","ali_name":"Bikinis","ali_id":200001866,"ali_parent_id":200574005},
{"name":"Men's Socks","ali_name":"Men's Socks","ali_id":200000384,"ali_parent_id":200574005},
{"name":"Men's Sleep and Lounge","ali_name":"Men's Sleep & Lounge","ali_id":200001813,"ali_parent_id":200574005},
{"name":"Men's Underwears","ali_name":"Men's Underwears","ali_id":200001865,"ali_parent_id":200574005},
{"name":"Women's Sleep and Lounge","ali_name":"Women's Sleep & Lounge","ali_id":200001894,"ali_parent_id":200574005},
{"name":"Women's Intimates","ali_name":"Women's Intimates","ali_id":200000349,"ali_parent_id":200574005},
{"name":"Women's Socks and Hosiery","ali_name":"Women's Socks & Hosiery","ali_id":200000854,"ali_parent_id":200574005},
{"name":"Apparel and Accessories","ali_name":"Apparel & Accessories","ali_id":3},
{"name":"Sportswears","ali_name":"Sportswears","ali_id":200003274,"ali_parent_id":3},
{"name":"Beauty and Health","ali_name":"Beauty & Health","ali_id":66},
{"name":"Beauty Equipment","ali_name":"Beauty Equipment","ali_id":201169002,"ali_parent_id":66},
{"name":"Bath and Shower","ali_name":"Bath & Shower","ali_id":200001288,"ali_parent_id":66},
{"name":"Fragrances and Deodorants","ali_name":"Fragrances & Deodorants","ali_id":200001221,"ali_parent_id":66},
{"name":"Hair Care and Styling","ali_name":"Hair Care & Styling","ali_id":200001168,"ali_parent_id":66},
{"name":"Health Care","ali_name":"Health Care","ali_id":200001355,"ali_parent_id":66},
{"name":"Makeup","ali_name":"Makeup","ali_id":660103,"ali_parent_id":66},
{"name":"Nail Art and Tools","ali_name":"Nail Art & Tools","ali_id":200001147,"ali_parent_id":66},
{"name":"Oral Hygiene","ali_name":"Oral Hygiene","ali_id":3305,"ali_parent_id":66},
{"name":"Sanitary Paper","ali_name":"Sanitary Paper","ali_id":1513,"ali_parent_id":66},
{"name":"Sex Products","ali_name":"Sex Products","ali_id":200001508,"ali_parent_id":66},
{"name":"Shaving and Hair Removal","ali_name":"Shaving & Hair Removal","ali_id":660302,"ali_parent_id":66},
{"name":"Skin Care","ali_name":"Skin Care","ali_id":3306,"ali_parent_id":66},
{"name":"Skin Care Tool","ali_name":"Skin Care Tool","ali_id":100000616,"ali_parent_id":66},
{"name":"Tattoo and Body Art","ali_name":"Tattoo & Body Art","ali_id":200001976,"ali_parent_id":66},
{"name":"Computer and Office","ali_name":"Computer & Office","ali_id":7},
{"name":"Desktops","ali_name":"Desktops","ali_id":701,"ali_parent_id":7},
{"name":"Laptop Parts and Accessories","ali_name":"Laptop Parts & Accessories","ali_id":200001083,"ali_parent_id":7},
{"name":"Computer Cables and Connectors","ali_name":"Computer Cables & Connectors","ali_id":70806,"ali_parent_id":7},
{"name":"Computer Cleaners","ali_name":"Computer Cleaners","ali_id":708022,"ali_parent_id":7},
{"name":"Computer Components","ali_name":"Computer Components","ali_id":200001076,"ali_parent_id":7},
{"name":"Computer Peripherals","ali_name":"Computer Peripherals","ali_id":200001081,"ali_parent_id":7},
{"name":"DIY Gaming Computer","ali_name":"DIY Gaming Computer","ali_id":200048142,"ali_parent_id":7},
{"name":"Demo board and Accessories","ali_name":"Demo board & Accessories","ali_id":200185144,"ali_parent_id":7},
{"name":"External Storage","ali_name":"External Storage","ali_id":200001074,"ali_parent_id":7},
{"name":"Industrial Computer and Accessories","ali_name":"Industrial Computer & Accessories","ali_id":100005330,"ali_parent_id":7},
{"name":"Internal Storage","ali_name":"Internal Storage","ali_id":200154144,"ali_parent_id":7},
{"name":"KVM Switches","ali_name":"KVM Switches","ali_id":100005329,"ali_parent_id":7},
{"name":"Laptops","ali_name":"Laptops","ali_id":702,"ali_parent_id":7},
{"name":"Mini PC","ali_name":"Mini PC","ali_id":70803003,"ali_parent_id":7},
{"name":"Networking","ali_name":"Networking","ali_id":200001077,"ali_parent_id":7},
{"name":"Office Electronics","ali_name":"Office Electronics","ali_id":200003782,"ali_parent_id":7},
{"name":"Office Software","ali_name":"Office Software","ali_id":200318143,"ali_parent_id":7},
{"name":"Servers","ali_name":"Servers","ali_id":703,"ali_parent_id":7},
{"name":"Tablet Accessories","ali_name":"Tablet Accessories","ali_id":200001085,"ali_parent_id":7},
{"name":"Tablets","ali_name":"Tablets","ali_id":200001086,"ali_parent_id":7},
{"name":"Electronics","ali_name":"Consumer Electronics","ali_id":44},
{"name":"Accessories and Parts","ali_name":"Accessories & Parts","ali_id":629,"ali_parent_id":44},
{"name":"Camera and Photo","ali_name":"Camera & Photo","ali_id":100000305,"ali_parent_id":44},
{"name":"Electronic Cigarettes","ali_name":"Electronic Cigarettes","ali_id":200003561,"ali_parent_id":44},
{"name":"Games and Accessories","ali_name":"Games & Accessories","ali_id":100000310,"ali_parent_id":44},
{"name":"Home Audio and Video","ali_name":"Home Audio & Video","ali_id":100000308,"ali_parent_id":44},
{"name":"Portable Audio and Video","ali_name":"Portable Audio & Video","ali_id":100000306,"ali_parent_id":44},
{"name":"Smart Electronics","ali_name":"Smart Electronics","ali_id":200003803,"ali_parent_id":44},
{"name":"Electronic Components and Supplies","ali_name":"Electronic Components & Supplies","ali_id":502},
{"name":"Active Components","ali_name":"Active Components","ali_id":4001,"ali_parent_id":502},
{"name":"EL Products","ali_name":"EL Products","ali_id":150412,"ali_parent_id":502},
{"name":"Electronic Accessories and Supplies","ali_name":"Electronic Accessories & Supplies","ali_id":4003,"ali_parent_id":502},
{"name":"Electronic Data Systems","ali_name":"Electronic Data Systems","ali_id":504,"ali_parent_id":502},
{"name":"Electronic Signs","ali_name":"Electronic Signs","ali_id":150407,"ali_parent_id":502},
{"name":"Electronics Production Machinery","ali_name":"Electronics Production Machinery","ali_id":4002,"ali_parent_id":502},
{"name":"Electronics Stocks","ali_name":"Electronics Stocks","ali_id":515,"ali_parent_id":502},
{"name":"Optoelectronic Displays","ali_name":"Optoelectronic Displays","ali_id":4004,"ali_parent_id":502},
{"name":"Other Electronic Components","ali_name":"Other Electronic Components","ali_id":4099,"ali_parent_id":502},
{"name":"Passive Components","ali_name":"Passive Components","ali_id":4005,"ali_parent_id":502},
{"name":"Food","ali_name":"Food","ali_id":2},
{"name":"Coffee1","ali_name":"Coffee1","ali_id":200001238,"ali_parent_id":2},
{"name":"Dried Fruit1","ali_name":"Dried Fruit1","ali_id":200001317,"ali_parent_id":2},
{"name":"Dried Goods / Local Specialties1","ali_name":"Dried Goods / Local Specialties1","ali_id":200003573,"ali_parent_id":2},
{"name":"Grain Products1","ali_name":"Grain Products1","ali_id":200001287,"ali_parent_id":2},
{"name":"Grocery","ali_name":"Grocery","ali_id":200180142,"ali_parent_id":2},
{"name":"Nut and Kernel1","ali_name":"Nut & Kernel1","ali_id":200001321,"ali_parent_id":2},
{"name":"Tea","ali_name":"Tea","ali_id":100007269,"ali_parent_id":2},
{"name":"Canned Food1","ali_name":"Canned Food1","ali_id":200183144,"ali_parent_id":2},
{"name":"Water/ Juices/ Drinks","ali_name":"Water/ Juices/ Drinks","ali_id":201249506,"ali_parent_id":2},
{"name":"Dried Fruit","ali_name":"Dried Fruit","ali_id":201254404,"ali_parent_id":2},
{"name":"Nut and Kernel","ali_name":"Nut & Kernel","ali_id":201253905,"ali_parent_id":2},
{"name":"Coffee","ali_name":"Coffee","ali_id":201248804,"ali_parent_id":2},
{"name":"Canned Food","ali_name":"Canned Food","ali_id":201248407,"ali_parent_id":2},
{"name":"Grain Products","ali_name":"Grain Products","ali_id":201254306,"ali_parent_id":2},
{"name":"Dried Goods / Local Specialties","ali_name":"Dried Goods / Local Specialties","ali_id":201248805,"ali_parent_id":2},
{"name":"Alcoholic Beverages","ali_name":"Alcoholic Beverages","ali_id":201345805,"ali_parent_id":2},
{"name":"Frozen Products","ali_name":"Frozen Products","ali_id":201374104,"ali_parent_id":2},
{"name":"Fish and Sea Food","ali_name":"Fish and Sea Food","ali_id":201376903,"ali_parent_id":2},
{"name":"Meat","ali_name":"Meat","ali_id":201379402,"ali_parent_id":2},
{"name":"Ready Meal","ali_name":"Ready Meal","ali_id":201376702,"ali_parent_id":2},
{"name":"Fruits and Berries","ali_name":"Fruits and Berries","ali_id":201379801,"ali_parent_id":2},
{"name":"Cheese","ali_name":"Cheese","ali_id":201379901,"ali_parent_id":2},
{"name":"Bread and Pastries","ali_name":"Bread and Pastries","ali_id":201378002,"ali_parent_id":2},
{"name":"Milk and Eggs","ali_name":"Milk and Eggs","ali_id":201376204,"ali_parent_id":2},
{"name":"Sausages","ali_name":"Sausages","ali_id":201378103,"ali_parent_id":2},
{"name":"Vegetables and Greens","ali_name":"Vegetables and Greens","ali_id":201373004,"ali_parent_id":2},
{"name":"Furniture","ali_name":"Furniture","ali_id":1503},
{"name":"Bar Furniture","ali_name":"Bar Furniture","ali_id":100001029,"ali_parent_id":1503},
{"name":"Café Furniture","ali_name":"Café Furniture","ali_id":200130145,"ali_parent_id":1503},
{"name":"Children Furniture","ali_name":"Children Furniture","ali_id":100003019,"ali_parent_id":1503},
{"name":"Commercial Furniture","ali_name":"Commercial Furniture","ali_id":150301,"ali_parent_id":1503},
{"name":"Furniture Accessories","ali_name":"Furniture Accessories","ali_id":3712,"ali_parent_id":1503},
{"name":"Furniture Parts","ali_name":"Furniture Parts","ali_id":3708,"ali_parent_id":1503},
{"name":"Home Furniture","ali_name":"Home Furniture","ali_id":150303,"ali_parent_id":1503},
{"name":"Office Furniture","ali_name":"Office Furniture","ali_id":150304,"ali_parent_id":1503},
{"name":"Other Furniture","ali_name":"Other Furniture","ali_id":150399,"ali_parent_id":1503},
{"name":"Outdoor Furniture","ali_name":"Outdoor Furniture","ali_id":150302,"ali_parent_id":1503},
{"name":"Hair Extensions and Wigs","ali_name":"Hair Extensions & Wigs","ali_id":200165144},
{"name":"Hair Tools and Accessories","ali_name":"Hair Tools & Accessories","ali_id":201303603,"ali_parent_id":200165144},
{"name":"Human Wigs( For Black)","ali_name":"Human Wigs( For Black)","ali_id":127886013,"ali_parent_id":200165144},
{"name":"Beauty Supply","ali_name":"Beauty Supply","ali_id":200317142,"ali_parent_id":200165144},
{"name":"Hair Salon Supply","ali_name":"Hair Salon Supply","ali_id":200319142,"ali_parent_id":200165144},
{"name":"Human Hair (For Black)","ali_name":"Human Hair (For Black)","ali_id":200168146,"ali_parent_id":200165144},
{"name":"Human Hair (For White)","ali_name":"Human Hair (For White)","ali_id":200166144,"ali_parent_id":200165144},
{"name":"Synthetic Hair","ali_name":"Synthetic Hair","ali_id":200167144,"ali_parent_id":200165144},
{"name":"Synthetic Hair(For White)","ali_name":"Synthetic Hair(For White)","ali_id":200396142,"ali_parent_id":200165144},
{"name":"Home and Garden","ali_name":"Home & Garden","ali_id":15},
{"name":"Kitchen,Dining and Bar","ali_name":"Kitchen,Dining & Bar","ali_id":200000920,"ali_parent_id":15},
{"name":"Arts,Crafts and Sewing","ali_name":"Arts,Crafts & Sewing","ali_id":200003937,"ali_parent_id":15},
{"name":"Collectibles","ali_name":"Collectibles","ali_id":200003998,"ali_parent_id":15},
{"name":"Festive and Party Supplies","ali_name":"Festive & Party Supplies","ali_id":100001824,"ali_parent_id":15},
{"name":"Garden Supplies","ali_name":"Garden Supplies","ali_id":125,"ali_parent_id":15},
{"name":"Home Decor","ali_name":"Home Decor","ali_id":3710,"ali_parent_id":15},
{"name":"Home Storage and Organization","ali_name":"Home Storage & Organization","ali_id":1541,"ali_parent_id":15},
{"name":"Home Textile","ali_name":"Home Textile","ali_id":405,"ali_parent_id":15},
{"name":"Household Merchandises","ali_name":"Household Merchandises","ali_id":200033149,"ali_parent_id":15},
{"name":"Pet Products","ali_name":"Pet Products","ali_id":100006664,"ali_parent_id":15},
{"name":"Appliances","ali_name":"Home Appliances","ali_id":6},
{"name":"Commercial Appliances","ali_name":"Commercial Appliances","ali_id":200235142,"ali_parent_id":6},
{"name":"Home Appliance Parts","ali_name":"Home Appliance Parts","ali_id":100000039,"ali_parent_id":6},
{"name":"Household Appliances","ali_name":"Household Appliances","ali_id":200294142,"ali_parent_id":6},
{"name":"Kitchen Appliances","ali_name":"Kitchen Appliances","ali_id":100000041,"ali_parent_id":6},
{"name":"Major Appliances","ali_name":"Major Appliances","ali_id":200293142,"ali_parent_id":6},
{"name":"Other Home Appliances","ali_name":"Other Home Appliances","ali_id":628,"ali_parent_id":6},
{"name":"Personal Care Appliances","ali_name":"Personal Care Appliances","ali_id":200165142,"ali_parent_id":6},
{"name":"Home Improvement","ali_name":"Home Improvement","ali_id":13},
{"name":"Bathroom Fixture","ali_name":"Bathroom Fixture","ali_id":200066142,"ali_parent_id":13},
{"name":"Boards","ali_name":"Boards","ali_id":100005320,"ali_parent_id":13},
{"name":"Building Glass","ali_name":"Building Glass","ali_id":1315,"ali_parent_id":13},
{"name":"Ceilings","ali_name":"Ceilings","ali_id":100007099,"ali_parent_id":13},
{"name":"Countertops,Vanity Tops and Table Tops","ali_name":"Countertops,Vanity Tops & Table Tops","ali_id":131210,"ali_parent_id":13},
{"name":"Curtain Walls and Accessories","ali_name":"Curtain Walls & Accessories","ali_id":100005265,"ali_parent_id":13},
{"name":"Doors, Gates and Windows","ali_name":"Doors, Gates & Windows","ali_id":100007064,"ali_parent_id":13},
{"name":"Earthwork Products","ali_name":"Earthwork Products","ali_id":100005281,"ali_parent_id":13},
{"name":"Electrical Equipment and Supplies","ali_name":"Electrical Equipment & Supplies","ali_id":5,"ali_parent_id":13},
{"name":"Family Intelligence System","ali_name":"Family Intelligence System","ali_id":200321150,"ali_parent_id":13},
{"name":"Flooring and Accessories","ali_name":"Flooring & Accessories","ali_id":100006266,"ali_parent_id":13},
{"name":"Functional Material","ali_name":"Functional Material","ali_id":200001751,"ali_parent_id":13},
{"name":"Garden Landscaping and Decking","ali_name":"Garden Landscaping & Decking","ali_id":12504,"ali_parent_id":13},
{"name":"Hardware","ali_name":"Hardware","ali_id":42,"ali_parent_id":13},
{"name":"Heating, Cooling and Vents","ali_name":"Heating, Cooling & Vents","ali_id":200366148,"ali_parent_id":13},
{"name":"Kitchen Fixture","ali_name":"Kitchen Fixture","ali_id":200066144,"ali_parent_id":13},
{"name":"Ladders and Scaffoldings","ali_name":"Ladders & Scaffoldings","ali_id":1330,"ali_parent_id":13},
{"name":"Landscaping Stone","ali_name":"Landscaping Stone","ali_id":131211,"ali_parent_id":13},
{"name":"Metal Building Materials","ali_name":"Metal Building Materials","ali_id":200001382,"ali_parent_id":13},
{"name":"Other Home Improvement","ali_name":"Other Home Improvement","ali_id":1333,"ali_parent_id":13},
{"name":"Painting Supplies and Wall Treatments","ali_name":"Painting Supplies & Wall Treatments","ali_id":200264142,"ali_parent_id":13},
{"name":"Plastic Building Materials","ali_name":"Plastic Building Materials","ali_id":100005310,"ali_parent_id":13},
{"name":"Plumbing","ali_name":"Plumbing","ali_id":200282142,"ali_parent_id":13},
{"name":"Quarry Stone and Slabs","ali_name":"Quarry Stone & Slabs","ali_id":1312,"ali_parent_id":13},
{"name":"Stairs and Stair Parts","ali_name":"Stairs & Stair Parts","ali_id":100007071,"ali_parent_id":13},
{"name":"Stone Carvings and Sculptures","ali_name":"Stone Carvings and Sculptures","ali_id":131207,"ali_parent_id":13},
{"name":"Sunrooms and Glass Houses","ali_name":"Sunrooms & Glass Houses","ali_id":100007082,"ali_parent_id":13},
{"name":"Tiles and Accessories","ali_name":"Tiles & Accessories","ali_id":100006285,"ali_parent_id":13},
{"name":"Timber","ali_name":"Timber","ali_id":100005335,"ali_parent_id":13},
{"name":"aaaa","ali_name":"aaaa","ali_id":200071142,"ali_parent_id":13},
{"name":"bbbb","ali_name":"bbbb","ali_id":200072142,"ali_parent_id":13},
{"name":"Installation Service","ali_name":"Installation Service","ali_id":201303704,"ali_parent_id":13},
{"name":"Jewelry and Accessories","ali_name":"Jewelry & Accessories","ali_id":36},
{"name":"Fashion Jewelry","ali_name":"Fashion Jewelry","ali_id":1509,"ali_parent_id":36},
{"name":"Fine Jewelry","ali_name":"Fine Jewelry","ali_id":200001680,"ali_parent_id":36},
{"name":"Jewelry Packaging and Display","ali_name":"Jewelry Packaging & Display","ali_id":200001479,"ali_parent_id":36},
{"name":"Jewelry Tools and Equipments","ali_name":"Jewelry Tools & Equipments","ali_id":200001478,"ali_parent_id":36},
{"name":"Smart Jewelry","ali_name":"Smart Jewelry","ali_id":200370154,"ali_parent_id":36},
{"name":"Jewelry Making","ali_name":"Jewelry Making","ali_id":201238105,"ali_parent_id":36},
{"name":"Customized Jewelry","ali_name":"Customized Jewelry","ali_id":201239108,"ali_parent_id":36},
{"name":"Lights and Lighting","ali_name":"Lights & Lighting","ali_id":39},
{"name":"Commercial Lighting","ali_name":"Commercial Lighting","ali_id":200001493,"ali_parent_id":39},
{"name":"Holiday Lighting","ali_name":"Holiday Lighting","ali_id":150403,"ali_parent_id":39},
{"name":"Indoor Lighting","ali_name":"Indoor Lighting","ali_id":1504,"ali_parent_id":39},
{"name":"LED Lighting","ali_name":"LED Lighting","ali_id":390501,"ali_parent_id":39},
{"name":"Lighting Accessories","ali_name":"Lighting Accessories","ali_id":530,"ali_parent_id":39},
{"name":"Lighting Bulbs and Tubes","ali_name":"Lighting Bulbs & Tubes","ali_id":150402,"ali_parent_id":39},
{"name":"Novelty Lighting","ali_name":"Novelty Lighting","ali_id":200001067,"ali_parent_id":39},
{"name":"Other Lights and Lighting Products","ali_name":"Other Lights & Lighting Products","ali_id":3999,"ali_parent_id":39},
{"name":"Outdoor Lighting","ali_name":"Outdoor Lighting","ali_id":150401,"ali_parent_id":39},
{"name":"Portable Lighting","ali_name":"Portable Lighting","ali_id":390503,"ali_parent_id":39},
{"name":"Professional Light","ali_name":"Professional Light","ali_id":200001704,"ali_parent_id":39},
{"name":"Special Engineering Lighting","ali_name":"Special Engineering Lighting","ali_id":200326144,"ali_parent_id":39},
{"name":"Night Lights","ali_name":"Night Lights","ali_id":39050508,"ali_parent_id":39},
{"name":"Luggage and Bags","ali_name":"Luggage & Bags","ali_id":1524},
{"name":"Luggage","ali_name":"Luggage","ali_id":201298604,"ali_parent_id":1524},
{"name":"Backpacks","ali_name":"Backpacks","ali_id":152401,"ali_parent_id":1524},
{"name":"Bag Parts and Accessories","ali_name":"Bag Parts & Accessories","ali_id":152409,"ali_parent_id":1524},
{"name":"Briefcases","ali_name":"Briefcases","ali_id":152402,"ali_parent_id":1524},
{"name":"Leisure Bags","ali_name":"Leisure Bags","ali_id":3806,"ali_parent_id":1524},
{"name":"Other Luggage and Bags","ali_name":"Other Luggage & Bags","ali_id":152499,"ali_parent_id":1524},
{"name":"Special Purpose Bags","ali_name":"Special Purpose Bags","ali_id":3805,"ali_parent_id":1524},
{"name":"Wallets and Holders","ali_name":"Wallets & Holders","ali_id":3803,"ali_parent_id":1524},
{"name":"School Bags","ali_name":"School Bags","ali_id":380520,"ali_parent_id":1524},
{"name":"Winter Bags","ali_name":"Winter Bags","ali_id":201295902,"ali_parent_id":1524},
{"name":"Summer Bags","ali_name":"Summer Bags","ali_id":201296801,"ali_parent_id":1524},
{"name":"Travel Accessories","ali_name":"Travel Accessories","ali_id":201296102,"ali_parent_id":1524},
{"name":"Travel Bags","ali_name":"Travel Bags","ali_id":201294604,"ali_parent_id":1524},
{"name":"Men's Bags","ali_name":"Men's Bags","ali_id":201337808,"ali_parent_id":1524},
{"name":"Women's Handbags","ali_name":"Women's Handbags","ali_id":201336907,"ali_parent_id":1524},
{"name":"Kids' Bags","ali_name":"Kids' Bags","ali_id":201376929,"ali_parent_id":1524},
{"name":"Chest Bags","ali_name":"Chest Bags","ali_id":201401303,"ali_parent_id":1524},
{"name":"Waist Packs","ali_name":"Waist Packs","ali_id":201396505,"ali_parent_id":1524},
{"name":"Mother and Kids","ali_name":"Mother & Kids","ali_id":1501},
{"name":"Activity and Gear","ali_name":"Activity & Gear","ali_id":200002039,"ali_parent_id":1501},
{"name":"Baby Care","ali_name":"Baby Care","ali_id":200001330,"ali_parent_id":1501},
{"name":"Baby Clothing","ali_name":"Baby Clothing","ali_id":310,"ali_parent_id":1501},
{"name":"Baby Food","ali_name":"Baby Food","ali_id":200364142,"ali_parent_id":1501},
{"name":"Baby Furniture","ali_name":"Baby Furniture","ali_id":200332158,"ali_parent_id":1501},
{"name":"Baby Shoes","ali_name":"Baby Shoes","ali_id":200000937,"ali_parent_id":1501},
{"name":"Baby Souvenirs","ali_name":"Baby Souvenirs","ali_id":200332157,"ali_parent_id":1501},
{"name":"Bedding","ali_name":"Bedding","ali_id":100003020,"ali_parent_id":1501},
{"name":"Children's Clothing","ali_name":"Children's Clothing","ali_id":311,"ali_parent_id":1501},
{"name":"Children's Shoes","ali_name":"Children's Shoes","ali_id":200000947,"ali_parent_id":1501},
{"name":"Diapering and Toilet Training","ali_name":"Diapering & Toilet Training","ali_id":200328147,"ali_parent_id":1501},
{"name":"Feeding","ali_name":"Feeding","ali_id":200002038,"ali_parent_id":1501},
{"name":"Pregnancy and Maternity","ali_name":"Pregnancy & Maternity","ali_id":200328149,"ali_parent_id":1501},
{"name":"Safety","ali_name":"Safety","ali_id":200002006,"ali_parent_id":1501},
{"name":"Baby StrollersandAccessories","ali_name":"Baby Strollers&Accessories","ali_id":201273175,"ali_parent_id":1501},
{"name":"Car Seats and Accessories","ali_name":"Car Seats & Accessories","ali_id":200329142,"ali_parent_id":1501},
{"name":"Kids Accessories","ali_name":"Kids Accessories","ali_id":201293501,"ali_parent_id":1501},
{"name":"Maternity Clothings","ali_name":"Maternity Clothings","ali_id":200000500,"ali_parent_id":1501},
{"name":"Phones and Telecommunications","ali_name":"Phones & Telecommunications","ali_id":509},
{"name":"Mobile Phone Accessories","ali_name":"Mobile Phone Accessories","ali_id":100001205,"ali_parent_id":509},
{"name":"Communication Equipment","ali_name":"Communication Equipment","ali_id":100001204,"ali_parent_id":509},
{"name":"Mobile Phones","ali_name":"Mobile Phones","ali_id":5090301,"ali_parent_id":509},
{"name":"Walkie Talkie Parts and Accessories","ali_name":"Walkie Talkie Parts & Accessories","ali_id":200380144,"ali_parent_id":509},
{"name":"Walkie Talkie","ali_name":"Walkie Talkie","ali_id":50906,"ali_parent_id":509},
{"name":"Mobile Phone Parts","ali_name":"Mobile Phone Parts","ali_id":201084002,"ali_parent_id":509},
{"name":"Sim Cards and Accessories","ali_name":"Sim Cards & Accessories","ali_id":200001598,"ali_parent_id":509},
{"name":"Security and Protection","ali_name":"Security & Protection","ali_id":30},
{"name":"Access Control","ali_name":"Access Control","ali_id":3030,"ali_parent_id":30},
{"name":"Building Automation","ali_name":"Building Automation","ali_id":200327231,"ali_parent_id":30},
{"name":"Disaster-relief Supplies","ali_name":"Disaster-relief Supplies","ali_id":200327211,"ali_parent_id":30},
{"name":"First Aid Kits","ali_name":"First Aid Kits","ali_id":200001791,"ali_parent_id":30},
{"name":"Fire Protection","ali_name":"Fire Protection","ali_id":3009,"ali_parent_id":30},
{"name":"Intercom","ali_name":"Intercom","ali_id":200004310,"ali_parent_id":30},
{"name":"IoT Devices","ali_name":"IoT Devices","ali_id":200330186,"ali_parent_id":30},
{"name":"Public Broadcasting","ali_name":"Public Broadcasting","ali_id":200327212,"ali_parent_id":30},
{"name":"Roadway Safety","ali_name":"Roadway Safety","ali_id":3015,"ali_parent_id":30},
{"name":"Safes","ali_name":"Safes","ali_id":200327196,"ali_parent_id":30},
{"name":"Security Alarm","ali_name":"Security Alarm","ali_id":200004311,"ali_parent_id":30},
{"name":"Security Inspection Device","ali_name":"Security Inspection Device","ali_id":200328267,"ali_parent_id":30},
{"name":"Self Defense Supplies","ali_name":"Self Defense Supplies","ali_id":200328217,"ali_parent_id":30},
{"name":"Smart Card System","ali_name":"Smart Card System","ali_id":200004309,"ali_parent_id":30},
{"name":"Transmission and Cables","ali_name":"Transmission & Cables","ali_id":200004343,"ali_parent_id":30},
{"name":"UAV System and Robot","ali_name":"UAV System & Robot","ali_id":200332185,"ali_parent_id":30},
{"name":"Video Surveillance","ali_name":"Video Surveillance","ali_id":3011,"ali_parent_id":30},
{"name":"Workplace Safety Supplies","ali_name":"Workplace Safety Supplies","ali_id":3007,"ali_parent_id":30},
{"name":"Shoes","ali_name":"Shoes","ali_id":322},
{"name":"Men's Shoes","ali_name":"Men's Shoes","ali_id":200131145,"ali_parent_id":322},
{"name":"Mules and Clogs","ali_name":"Mules & Clogs","ali_id":200001000,"ali_parent_id":322},
{"name":"Other Shoes","ali_name":"Other Shoes","ali_id":32299,"ali_parent_id":322},
{"name":"Shoe Accessories","ali_name":"Shoe Accessories","ali_id":32210,"ali_parent_id":322},
{"name":"Women's Shoes","ali_name":"Women's Shoes","ali_id":200133142,"ali_parent_id":322},
{"name":"Special Category","ali_name":"Special Category","ali_id":200001075},
{"name":"Additional Pay on Your Order","ali_name":"Additional Pay on Your Order","ali_id":200001079,"ali_parent_id":200001075},
{"name":"Beginner for XinXiaoYuan","ali_name":"Beginner for XinXiaoYuan","ali_id":200004270,"ali_parent_id":200001075},
{"name":"Checkout Link","ali_name":"Checkout Link","ali_id":200001078,"ali_parent_id":200001075},
{"name":"Platform Coupon","ali_name":"Platform Coupon","ali_id":200003560,"ali_parent_id":200001075},
{"name":"Custom-made Charge","ali_name":"Custom-made Charge","ali_id":200165143,"ali_parent_id":200001075},
{"name":"Custom-made-Charge","ali_name":"Custom-made-Charge","ali_id":200376142,"ali_parent_id":200001075},
{"name":"Down payment/ Purchasing Agent","ali_name":"Down payment/ Purchasing Agent","ali_id":200001627,"ali_parent_id":200001075},
{"name":"Giftcard1","ali_name":"Giftcard1","ali_id":200024143,"ali_parent_id":200001075},
{"name":"Giveaways","ali_name":"Giveaways","ali_id":200001621,"ali_parent_id":200001075},
{"name":"Mobile Phone Recharge","ali_name":"Mobile Phone Recharge","ali_id":200003892,"ali_parent_id":200001075},
{"name":"Other","ali_name":"Other","ali_id":200001620,"ali_parent_id":200001075},
{"name":"Overseas Warehouse","ali_name":"Overseas Warehouse","ali_id":200003864,"ali_parent_id":200001075},
{"name":"Test","ali_name":"Test","ali_id":200004358,"ali_parent_id":200001075},
{"name":"Upcoming Products","ali_name":"Upcoming Products","ali_id":200001623,"ali_parent_id":200001075},
{"name":"Wallpaper Sample","ali_name":"Wallpaper Sample","ali_id":200726001,"ali_parent_id":200001075},
{"name":"Event Ticket","ali_name":"Event Ticket","ali_id":201174708,"ali_parent_id":200001075},
{"name":"Coupons","ali_name":"Coupons","ali_id":201249605,"ali_parent_id":200001075},
{"name":"Giftcard","ali_name":"Giftcard","ali_id":201252004,"ali_parent_id":200001075},
{"name":"Software andGames (without VAT)","ali_name":"Software &Games (without VAT)","ali_id":201303705,"ali_parent_id":200001075},
{"name":"Prepaid Digital Codes","ali_name":"Prepaid Digital Codes","ali_id":201350802,"ali_parent_id":200001075},
{"name":"Mini App","ali_name":"Mini App","ali_id":201355115,"ali_parent_id":200001075},
{"name":"Sports and Entertainment","ali_name":"Sports & Entertainment","ali_id":18},
{"name":"Camping and Hiking","ali_name":"Camping & Hiking","ali_id":100005529,"ali_parent_id":18},
{"name":"Cheerleading","ali_name":"Cheerleading","ali_id":100005880,"ali_parent_id":18},
{"name":"Cycling","ali_name":"Cycling","ali_id":200003500,"ali_parent_id":18},
{"name":"Entertainment","ali_name":"Entertainment","ali_id":200003538,"ali_parent_id":18},
{"name":"Fishing","ali_name":"Fishing","ali_id":100005537,"ali_parent_id":18},
{"name":"Fitness and Body Building","ali_name":"Fitness & Body Building","ali_id":100005371,"ali_parent_id":18},
{"name":"Golf","ali_name":"Golf","ali_id":100005360,"ali_parent_id":18},
{"name":"Horse Riding","ali_name":"Horse Riding","ali_id":100005551,"ali_parent_id":18},
{"name":"Hunting","ali_name":"Hunting","ali_id":100005563,"ali_parent_id":18},
{"name":"Musical Instruments","ali_name":"Musical Instruments","ali_id":100005481,"ali_parent_id":18},
{"name":"Other Sports and Entertainment Product","ali_name":"Other Sports & Entertainment Product","ali_id":200004194,"ali_parent_id":18},
{"name":"Racquet Sports","ali_name":"Racquet Sports","ali_id":200003540,"ali_parent_id":18},
{"name":"Roller,Skateboard","ali_name":"Roller,Skateboard","ali_id":200003541,"ali_parent_id":18},
{"name":"Shooting","ali_name":"Shooting","ali_id":100005571,"ali_parent_id":18},
{"name":"Skiing and Snowboarding","ali_name":"Skiing & Snowboarding","ali_id":200003543,"ali_parent_id":18},
{"name":"Sneakers","ali_name":"Sneakers","ali_id":200000950,"ali_parent_id":18},
{"name":"Sport Bags","ali_name":"Sport Bags","ali_id":200046142,"ali_parent_id":18},
{"name":"Sports Bags(hidden)","ali_name":"Sports Bags(hidden)","ali_id":200004217,"ali_parent_id":18},
{"name":"Sports Competitions","ali_name":"Sports Competitions","ali_id":200297143,"ali_parent_id":18},
{"name":"Sportswear and Accessories","ali_name":"Sportswear & Accessories","ali_id":301,"ali_parent_id":18},
{"name":"Team Sports","ali_name":"Team Sports","ali_id":200378143,"ali_parent_id":18},
{"name":"Water Sports","ali_name":"Water Sports","ali_id":100005657,"ali_parent_id":18},
{"name":"Tools","ali_name":"Tools","ali_id":1420},
{"name":"Machinery and Accessories","ali_name":"Machinery & Accessories","ali_id":100007188,"ali_parent_id":1420},
{"name":"Material Handling Tools","ali_name":"Material Handling Tools","ali_id":100007498,"ali_parent_id":1420},
{"name":"Abrasive Tools","ali_name":"Abrasive Tools","ali_id":4204,"ali_parent_id":1420},
{"name":"Abrasives","ali_name":"Abrasives","ali_id":1428,"ali_parent_id":1420},
{"name":"Construction Tools","ali_name":"Construction Tools","ali_id":142016,"ali_parent_id":1420},
{"name":"Drill Bit","ali_name":"Drill Bit","ali_id":100006468,"ali_parent_id":1420},
{"name":"Garden Tools","ali_name":"Garden Tools","ali_id":12503,"ali_parent_id":1420},
{"name":"Hand Tools","ali_name":"Hand Tools","ali_id":142003,"ali_parent_id":1420},
{"name":"Measurement and Analysis Instruments","ali_name":"Measurement & Analysis Instruments","ali_id":1537,"ali_parent_id":1420},
{"name":"Other Tools","ali_name":"Other Tools","ali_id":142099,"ali_parent_id":1420},
{"name":"Power Tools","ali_name":"Power Tools","ali_id":1417,"ali_parent_id":1420},
{"name":"Riveter Guns","ali_name":"Riveter Guns","ali_id":200183146,"ali_parent_id":1420},
{"name":"Saw Blade","ali_name":"Saw Blade","ali_id":100006471,"ali_parent_id":1420},
{"name":"Tool Parts","ali_name":"Tool Parts","ali_id":142001,"ali_parent_id":1420},
{"name":"Tool Sets","ali_name":"Tool Sets","ali_id":200003955,"ali_parent_id":1420},
{"name":"Tools Packaging","ali_name":"Tools Packaging","ali_id":100007485,"ali_parent_id":1420},
{"name":"Welding and Soldering Supplies","ali_name":"Welding & Soldering Supplies","ali_id":1427,"ali_parent_id":1420},
{"name":"Welding Equipment","ali_name":"Welding Equipment","ali_id":1440,"ali_parent_id":1420},
{"name":"Woodworking Machinery","ali_name":"Woodworking Machinery","ali_id":1431,"ali_parent_id":1420},
{"name":"Woodworking Machinery Parts","ali_name":"Woodworking Machinery Parts","ali_id":4338,"ali_parent_id":1420},
{"name":"Power Tool Parts and Accessories","ali_name":"Power Tool Parts & Accessories","ali_id":201252405,"ali_parent_id":1420},
{"name":"Toys and Hobbies","ali_name":"Toys & Hobbies","ali_id":26},
{"name":"Arts and Crafts, DIY toys","ali_name":"Arts & Crafts, DIY toys","ali_id":200389156,"ali_parent_id":26},
{"name":"Baby and Toddler Toys","ali_name":"Baby & Toddler Toys","ali_id":100001698,"ali_parent_id":26},
{"name":"Building and Construction Toys","ali_name":"Building & Construction Toys","ali_id":200001383,"ali_parent_id":26},
{"name":"Classic Toys","ali_name":"Classic Toys","ali_id":100001715,"ali_parent_id":26},
{"name":"Diecasts and Toy Vehicles","ali_name":"Diecasts & Toy Vehicles","ali_id":100001793,"ali_parent_id":26},
{"name":"Dolls and Accessories","ali_name":"Dolls & Accessories","ali_id":200001389,"ali_parent_id":26},
{"name":"Dolls and Stuffed Toys","ali_name":"Dolls & Stuffed Toys","ali_id":200001725,"ali_parent_id":26},
{"name":"Electronic Toys","ali_name":"Electronic Toys","ali_id":200001388,"ali_parent_id":26},
{"name":"High Tech Toys","ali_name":"High Tech Toys","ali_id":200386159,"ali_parent_id":26},
{"name":"Hobby and Collectibles","ali_name":"Hobby & Collectibles","ali_id":200388154,"ali_parent_id":26},
{"name":"Kid's Party","ali_name":"Kid's Party","ali_id":200389146,"ali_parent_id":26},
{"name":"Learning and Education","ali_name":"Learning & Education","ali_id":100001714,"ali_parent_id":26},
{"name":"Novelty and Gag Toys","ali_name":"Novelty & Gag Toys","ali_id":200001384,"ali_parent_id":26},
{"name":"Outdoor Fun and Sports","ali_name":"Outdoor Fun & Sports","ali_id":100001719,"ali_parent_id":26},
{"name":"Pools and Water Fun","ali_name":"Pools & Water Fun","ali_id":200389159,"ali_parent_id":26},
{"name":"Pretend Play","ali_name":"Pretend Play","ali_id":100001716,"ali_parent_id":26},
{"name":"Games and Puzzles","ali_name":"Games and Puzzles","ali_id":200001726,"ali_parent_id":26},
{"name":"Remote Control Toys","ali_name":"Remote Control Toys","ali_id":200001385,"ali_parent_id":26},
{"name":"Stress Relief Toy","ali_name":"Stress Relief Toy","ali_id":200246142,"ali_parent_id":26},
{"name":"Stuffed Animals and Plush","ali_name":"Stuffed Animals & Plush","ali_id":200001387,"ali_parent_id":26},
{"name":"Action and Toy Figures","ali_name":"Action & Toy Figures","ali_id":201292714,"ali_parent_id":26},
{"name":"Watches","ali_name":"Watches","ali_id":1511},
{"name":"Children's Watches","ali_name":"Children's Watches","ali_id":200362144,"ali_parent_id":1511},
{"name":"Couple Watches","ali_name":"Couple Watches","ali_id":200362143,"ali_parent_id":1511},
{"name":"Men's Watches","ali_name":"Men's Watches","ali_id":200362146,"ali_parent_id":1511},
{"name":"Pocket and Fob Watches","ali_name":"Pocket & Fob Watches","ali_id":200000126,"ali_parent_id":1511},
{"name":"Watches Accessories","ali_name":"Watches Accessories","ali_id":200000121,"ali_parent_id":1511},
{"name":"Women's Watches","ali_name":"Women's Watches","ali_id":200362145,"ali_parent_id":1511},
{"name":"Weddings and Events","ali_name":"Weddings & Events","ali_id":320},
{"name":"Special Occasion Dresses","ali_name":"Special Occasion Dresses","ali_id":200000749,"ali_parent_id":320},
{"name":"Wedding Accessories","ali_name":"Wedding Accessories","ali_id":100005787,"ali_parent_id":320},
{"name":"Wedding Dresses","ali_name":"Wedding Dresses","ali_id":32005,"ali_parent_id":320},
{"name":"Wedding Party Dress","ali_name":"Wedding Party Dress","ali_id":200000410,"ali_parent_id":320},
{"name":"Formal Occasion Dresses","ali_name":"Formal Occasion Dresses","ali_id":201236002,"ali_parent_id":320},
{"name":"Bespoke Occasion Dresses","ali_name":"Bespoke Occasion Dresses","ali_id":201238406,"ali_parent_id":320},
{"name":"Men's Clothing","ali_name":"Men's Clothing","ali_id":200000343},
{"name":"Pants","ali_name":"Pants","ali_id":201240601,"ali_parent_id":200000343},
{"name":"Sweaters","ali_name":"Sweaters","ali_id":201236604,"ali_parent_id":200000343},
{"name":"Coats and Jackets","ali_name":"Coats & Jackets","ali_id":200000795,"ali_parent_id":200000343},
{"name":"Hoodies and Sweatshirts","ali_name":"Hoodies & Sweatshirts","ali_id":200000344,"ali_parent_id":200000343},
{"name":"Jeans","ali_name":"Jeans","ali_id":200000378,"ali_parent_id":200000343},
{"name":"Men's Sets","ali_name":"Men's Sets","ali_id":200003495,"ali_parent_id":200000343},
{"name":"Shirts","ali_name":"Shirts","ali_id":348,"ali_parent_id":200000343},
{"name":"Shorts","ali_name":"Shorts","ali_id":200005141,"ali_parent_id":200000343},
{"name":"Suits and Blazer","ali_name":"Suits & Blazer","ali_id":200001819,"ali_parent_id":200000343},
{"name":"Swimwears","ali_name":"Swimwears","ali_id":200001860,"ali_parent_id":200000343},
{"name":"Tops and Tees","ali_name":"Tops & Tees","ali_id":200000779,"ali_parent_id":200000343},
{"name":"Novelty and Special Use","ali_name":"Novelty & Special Use","ali_id":200000532},
{"name":"Exotic Apparel","ali_name":"Exotic Apparel","ali_id":200000740,"ali_parent_id":200000532},
{"name":"Stage and Dance Wear","ali_name":"Stage & Dance Wear","ali_id":100005769,"ali_parent_id":200000532},
{"name":"Work Wear and Uniforms","ali_name":"Work Wear & Uniforms","ali_id":200000668,"ali_parent_id":200000532},
{"name":"World Apparel","ali_name":"World Apparel","ali_id":200000666,"ali_parent_id":200000532},
{"name":"Costumes and Accessories","ali_name":"Costumes & Accessories","ali_id":200000663,"ali_parent_id":200000532},
{"name":"Functional Apparel","ali_name":"Functional Apparel","ali_id":201302006,"ali_parent_id":200000532},
{"name":"Apparel Accessories","ali_name":"Apparel Accessories","ali_id":200000297},
{"name":"Arm Warmers","ali_name":"Arm Warmers","ali_id":200000392,"ali_parent_id":200000297},
{"name":"Belt Buckle","ali_name":"Belt Buckle","ali_id":200301150,"ali_parent_id":200000297},
{"name":"Belts","ali_name":"Belts","ali_id":200000298,"ali_parent_id":200000297},
{"name":"Collar Stays","ali_name":"Collar Stays","ali_id":200346143,"ali_parent_id":200000297},
{"name":"Cummerbunds","ali_name":"Cummerbunds","ali_id":200000306,"ali_parent_id":200000297},
{"name":"Earmuffs","ali_name":"Earmuffs","ali_id":200000393,"ali_parent_id":200000297},
{"name":"Eyewear and Accessories","ali_name":"Eyewear & Accessories","ali_id":200000440,"ali_parent_id":200000297},
{"name":"Gloves and Mittens","ali_name":"Gloves & Mittens","ali_id":200000394,"ali_parent_id":200000297},
{"name":"Handkerchiefs","ali_name":"Handkerchiefs","ali_id":200000400,"ali_parent_id":200000297},
{"name":"Hats and Caps","ali_name":"Hats & Caps","ali_id":200000402,"ali_parent_id":200000297},
{"name":"Headwear","ali_name":"Headwear","ali_id":200000395,"ali_parent_id":200000297},
{"name":"Knee Sleeve","ali_name":"Knee Sleeve","ali_id":200295143,"ali_parent_id":200000297},
{"name":"Mask","ali_name":"Mask","ali_id":200295142,"ali_parent_id":200000297},
{"name":"Pocket Squares","ali_name":"Pocket Squares","ali_id":200392144,"ali_parent_id":200000297},
{"name":"Scarf, Hat and Glove Sets","ali_name":"Scarf, Hat & Glove Sets","ali_id":33906,"ali_parent_id":200000297},
{"name":"Scarves and Wraps","ali_name":"Scarves & Wraps","ali_id":200000399,"ali_parent_id":200000297},
{"name":"Suspenders","ali_name":"Suspenders","ali_id":3280111,"ali_parent_id":200000297},
{"name":"Ties","ali_name":"Ties","ali_id":200000305,"ali_parent_id":200000297},
{"name":"DIY Accessories","ali_name":"DIY Accessories","ali_id":201159809,"ali_parent_id":200000297},
{"name":"Fashionable Canes","ali_name":"Fashionable Canes","ali_id":201161809,"ali_parent_id":200000297},
{"name":"Apparel Fabrics and Textiles","ali_name":"Apparel Fabrics & Textiles","ali_id":201196803,"ali_parent_id":200000297},
{"name":"New Headwear","ali_name":"New Headwear","ali_id":201303301,"ali_parent_id":200000297},
{"name":"Women's Clothing","ali_name":"Women's Clothing","ali_id":200000345},
{"name":"Blazer and Suits","ali_name":"Blazer & Suits","ali_id":200001912,"ali_parent_id":200000345},
{"name":"Blouses and Shirts","ali_name":"Blouses & Shirts","ali_id":200000346,"ali_parent_id":200000345},
{"name":"Coats and Jackets","ali_name":"Coats & Jackets","ali_id":200000796,"ali_parent_id":200000345},
{"name":"Dresses","ali_name":"Dresses","ali_id":200000347,"ali_parent_id":200000345},
{"name":"Hoodies and Sweatshirts","ali_name":"Hoodies & Sweatshirts","ali_id":200000348,"ali_parent_id":200000345},
{"name":"Jeans","ali_name":"Jeans","ali_id":200000361,"ali_parent_id":200000345},
{"name":"Jumpsuits, Playsuits and Bodysuits","ali_name":"Jumpsuits, Playsuits & Bodysuits","ali_id":200000362,"ali_parent_id":200000345},
{"name":"Leggings","ali_name":"Leggings","ali_id":200000865,"ali_parent_id":200000345},
{"name":"Pants and Capris","ali_name":"Pants & Capris","ali_id":200000366,"ali_parent_id":200000345},
{"name":"Shorts","ali_name":"Shorts","ali_id":200000367,"ali_parent_id":200000345},
{"name":"Skirts","ali_name":"Skirts","ali_id":349,"ali_parent_id":200000345},
{"name":"Sweaters","ali_name":"Sweaters","ali_id":200000373,"ali_parent_id":200000345},
{"name":"Swimwears","ali_name":"Swimwears","ali_id":200001918,"ali_parent_id":200000345},
{"name":"Tops and Tees","ali_name":"Tops & Tees","ali_id":200000778,"ali_parent_id":200000345},
{"name":"Women's Sets","ali_name":"Women's Sets","ali_id":200003494,"ali_parent_id":200000345},
{"name":"Jumpsuits, Rompers/Playsuits and Bodysuits","ali_name":"Jumpsuits, Rompers/Playsuits & Bodysuits","ali_id":201240602,"ali_parent_id":200000345},
{"name":"SweatersandJumpers","ali_name":"Sweaters&Jumpers","ali_id":201241002,"ali_parent_id":200000345},
{"name":"Women's Tops","ali_name":"Women's Tops","ali_id":201303001,"ali_parent_id":200000345},
{"name":"Virtual Products","ali_name":"Virtual Products","ali_id":201169612},
{"name":"Offline Activity For New Seller","ali_name":"Offline Activity For New Seller","ali_id":201377714,"ali_parent_id":201169612},
{"name":"Coupons","ali_name":"Coupons","ali_id":201355944,"ali_parent_id":201169612},
{"name":"Event Ticket","ali_name":"Event Ticket","ali_id":201356254,"ali_parent_id":201169612},
{"name":"Prepaid Digital Codes","ali_name":"Prepaid Digital Codes","ali_id":201353146,"ali_parent_id":201169612},
{"name":"Giftcard","ali_name":"Giftcard","ali_id":201352363,"ali_parent_id":201169612},
{"name":"Software and Games","ali_name":"Software & Games","ali_id":201360737,"ali_parent_id":201169612},
{"name":"Mobile Phone Recharge","ali_name":"Mobile Phone Recharge","ali_id":201354843,"ali_parent_id":201169612},
{"name":"Motorcycle Equipments and Parts","ali_name":"Motorcycle Equipments & Parts","ali_id":201355758},
{"name":"Motorcycle and ATV","ali_name":"Motorcycle & ATV","ali_id":201352247,"ali_parent_id":201355758},
{"name":"Ornamental and Cleaning & Protection","ali_name":"Ornamental & Cleaning & Protection","ali_id":201357051,"ali_parent_id":201355758},
{"name":"Motorcycle Parts","ali_name":"Motorcycle Parts","ali_id":201359843,"ali_parent_id":201355758},
{"name":"Motorcycle Accessories","ali_name":"Motorcycle Accessories","ali_id":201359147,"ali_parent_id":201355758},
{"name":"Motorcycle Equipments","ali_name":"Motorcycle Equipments","ali_id":201352950,"ali_parent_id":201355758},
{"name":"Aircraft","ali_name":"Aircraft","ali_id":201371802,"ali_parent_id":201355758},
{"name":"Boats","ali_name":"Boats","ali_id":201377402,"ali_parent_id":201355758}]

*/