import React from 'react'
import styles from '../stylesheets/menu.module.sass'
import DropList from './DropList'
import jumpTo from '../../../modules/Navigation'
export default function Menu({
  departments,
}) {
  return (
    <div className={styles.outbox}>
      {/* lists */}
      <div className={`${styles.lists}`}>
        {/* departments */}
        {departments && departments.map(d =>
          <div className={styles.tag}
            key={d.departmentName}
          >
            <DropList className={styles.droplist}
              clickCategory={(c) => jumpTo(`/${c.replace(/&/g,'')}`)}
              department={d.departmentName}
              categories={d.categories?d.categories.split(';'):null}
            />
          </div>
        )}
      </div>
    </div>
  )
}
